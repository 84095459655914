import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./footer.css";
import '../../index.css'
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../hooks/useTranslation";
import { BASE_URL_IMAGE } from "../../constants/constants";
import styled from "styled-components";
import { FaFacebook, FaInstagram, FaTwitter, FaPinterest, FaLinkedin, FaYoutube, FaGoogle } from "react-icons/fa";
import CustomerNewsletterServices from "../../services/CustomerNewsletterServices";
import { setNotifyMessage } from "../../Store/reducers/notifySlice";
import { CiCircleChevRight } from "react-icons/ci";

const Footer = () => {
  const [drop1, setDrop1] = useState(false);
  const [drop2, setDrop2] = useState(false);
  const [drop3, setDrop3] = useState(false);
  const [drop4, setDrop4] = useState(false);

  const { showTranslatedValue } = useTranslation();
  const dispatch = useDispatch();

  const footerData = useSelector(store => store.storeSettings.footer);

  const newsletterRef = useRef();

  const handleNewsletterSubmit = async () => {

    const email = newsletterRef.current.value;

    const validateEmail = (email) => {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    }

    if (!validateEmail(email)) {
      dispatch(setNotifyMessage("Please Enter a Valid Email"));
      return;
    }

    try {
      const res = await CustomerNewsletterServices.addCustomerToNewsletter({ email });
      dispatch(setNotifyMessage(res.message));
    } catch (error) {
      console.log(error);
    }

    newsletterRef.current.value = "";
  }

  const getSocialIcon = (name) => {
    const newName = name?.toLowerCase().trim();
    const socialIcons = {
      facebook: <FaFacebook />,
      linkedin: <FaLinkedin />,
      twitter: <FaTwitter />,
      instagram: <FaInstagram />,
      pinterest: <FaPinterest />,
      youtube: <FaYoutube />,
      google: <FaGoogle />,
    }
    return socialIcons[newName];
  }
  if (!footerData) {
    return;
  }

  return (
    <Wrapper>
      {/* ----------> Footer Common for all device <----------------- */}

      {/* --------> Footer Section-1 <---------------- */}
      {footerData?.showFooterSection1Status && <div className="w-full bg-[#fff9f2]">
        <div className="home8" >
          <div className="home81 flex items-start justify-between" >

            <div className="home811">
              <img src={`${BASE_URL_IMAGE}${footerData?.footerSection1Img1}`} alt="" />
              <h5>{showTranslatedValue(footerData?.footerSection1Title1
              )}</h5>
              <div className="text" dangerouslySetInnerHTML={{ __html: showTranslatedValue(footerData?.footerSection1Desc1) }}>
              </div>
            </div>

            <div className="home811">
              <img src={`${BASE_URL_IMAGE}${footerData?.footerSection1Img2}`} alt="" />
              <h5>{showTranslatedValue(footerData?.footerSection1Title2
              )}</h5>
              <div className="text" dangerouslySetInnerHTML={{ __html: showTranslatedValue(footerData?.footerSection1Desc2) }}>
              </div>
            </div>

            <div className="home811">
              <img src={`${BASE_URL_IMAGE}${footerData?.footerSection1Img3}`} alt="" />
              <h5>{showTranslatedValue(footerData?.footerSection1Title3
              )}</h5>
              <div className="text" dangerouslySetInnerHTML={{ __html: showTranslatedValue(footerData?.footerSection1Desc3) }}>
              </div>
            </div>

            <div className="home811">
              <img src={`${BASE_URL_IMAGE}${footerData?.footerSection1Img4}`} alt="" />
              <h5>{showTranslatedValue(footerData?.footerSection1Title4
              )}</h5>
              <div className="text" dangerouslySetInnerHTML={{ __html: showTranslatedValue(footerData?.footerSection1Desc4) }}>
              </div>
            </div>

          </div>
        </div >
      </div>}

      {/* ----------> Footer for Desktop View <----------------- */}

      {/* --------> Footer Section-2 <---------------- */}
      <div className="overflow-hidden">
        {footerData?.showFooterSection2Status &&
          <div className="home9 lg:px-[6rem] px-[3rem]"
            style={{ background: `url("${BASE_URL_IMAGE + footerData?.footerSection2Img[0]}") no-repeat center center/cover` }}
          >
            <div className="home91" >
              <div className="flex items-end justify-start gap-6 w-full">
                <div className="w-[90%]">
                  <p className="title">{showTranslatedValue(footerData?.footerSection2SubTitle)}</p>
                  <input
                    type="text"
                    className="send-input w-full"
                    placeholder={showTranslatedValue(footerData?.footerSection2Title)}
                    ref={newsletterRef}
                  />
                </div>
                <div className="w-[20%] relative md:mb-[3rem] mb-[1rem]">
                  <button onClick={handleNewsletterSubmit} className="lg:w-[20%] mt-[150px] md:mt-0"> <CiCircleChevRight className="w-[2rem] h-[2rem]" /> </button>
                </div>
              </div>
              <div className="text" dangerouslySetInnerHTML={{ __html: showTranslatedValue(footerData?.footerSection2Desc) }}>
              </div>
            </div>
          </div>}

        {/* --------> Footer Section-3 <---------------- */}
        {footerData?.showFooterSection3Status && <div className="home-10 flex">
          <div className="home-101 h-full">
            <div className="home-1011 flex justify-between">
              <div className="home-10111">
                <p>{showTranslatedValue(footerData?.footerSection3SubTitle)}</p>
                <h1>{showTranslatedValue(footerData?.footerSection3Title)}</h1>
                <div className="text" dangerouslySetInnerHTML={{ __html: showTranslatedValue(footerData?.footerSection3Desc) }}>
                </div></div>
              <div className="home-10112">
                <img src={`${BASE_URL_IMAGE}${footerData?.footerSection3Img2}`} alt="" />
              </div>
            </div>
            <div className="home-1012 relative">
              <img src={`${BASE_URL_IMAGE}${footerData?.footerSection3Img1}`} className="h-full" alt="" />
              <b className="absolute">{showTranslatedValue(footerData?.footerSection3Img2Desc)}</b>
            </div>
          </div>
          <div className="home-102 h-full">
            <img src={`${BASE_URL_IMAGE}${footerData?.footerSection3Img3}`} className="h-full w-full" alt="" />
          </div>
        </div>}
      </div >

      {/* --------> Footer Section-4 <---------------- */}
      {
        footerData?.showFooterSection4Status && <footer className="footer">
          <div className="foot11-1 flex flex-col">
            <h5 className="footer-title">
              {showTranslatedValue(footerData?.footerSection4Title)}
            </h5>
          </div>

          <div className="foot1 flex justify-between gap-2">

            {footerData?.footerBlock1status && <div className="foot11 flex flex-col whitespace-nowrap">
              <h5>{showTranslatedValue(footerData?.block1_title)}</h5>
              <div className="foot">
                {[1, 2, 3, 4, 5].map((val) => {
                  const subTitle = showTranslatedValue(footerData[`block1_sub_title${val}`]);
                  const subLink = footerData[`block1_sub_link${val}`];
                  return (subTitle && <Link to={subLink} key={val} className="flex gap-2 items-center">{getSocialIcon(subTitle)}{subTitle}</Link>)
                })}
              </div>
            </div>}

            {footerData?.footerBlock2status && <div className="foot12 flex flex-col">
              <h5>{showTranslatedValue(footerData?.block2_title)}</h5>
              <div className="foot">
                {[1, 2, 3, 4, 5].map((val) => {
                  const subTitle = showTranslatedValue(footerData[`block2_sub_title${val}`]);
                  const subLink = footerData[`block2_sub_link${val}`];
                  return (subTitle && <Link to={subLink} key={val} className="flex gap-2 items-center">{getSocialIcon(subTitle)}{subTitle}</Link>)
                })}
              </div>
            </div>}

            {footerData?.footerBlock3status && <div className="foot13 flex flex-col">
              <h5>{showTranslatedValue(footerData?.block3_title)}</h5>
              <div className="foot">
                {[1, 2, 3, 4, 5].map((val) => {
                  const subTitle = showTranslatedValue(footerData[`block3_sub_title${val}`]);
                  const subLink = footerData[`block3_sub_link${val}`];
                  return (subTitle && <Link to={subLink} key={val} className="whitespace-nowrap flex gap-2 items-center">{getSocialIcon(subTitle)}{subTitle}</Link>)
                })}
              </div>
            </div>}

            {footerData?.footerBlock4status && <div className="foot14 flex flex-col">
              <h5>{showTranslatedValue(footerData?.block4_title)}</h5>
              <div className="foot flex flex-col">
                {[1, 2, 3, 4, 5].map((val) => {
                  const subTitle = showTranslatedValue(footerData[`block4_sub_title${val}`]);
                  const subLink = footerData[`block4_sub_link${val}`];

                  return (subTitle && <p><Link to={subLink} key={val} className="whitespace-nowrap flex gap-2 items-center">{getSocialIcon(subTitle)}{subTitle}</Link></p>)
                })}
                {/* <h5>
                  {showTranslatedValue(footerData?.block4_sub_title1)}
                </h5> */}
              </div>
              {/* <p>{showTranslatedValue(footerData?.block4_sub_title2)}</p> */}
            </div>}

          </div>
          <div className="foot2">{showTranslatedValue(footerData?.footerSection4SubTitle)}</div>
        </footer>
      }

      {/* ----------> Footer for Mobile View <----------------- */}

      {/* --------> Footer Section-4 <---------------- */}
      {
        footerData?.showFooterSection4Status && <footer className="footer-0">
          <div className="foot1-0">

            <div className="foot11-1 flex flex-col">
              <h5 className="footer-title">
                {showTranslatedValue(footerData?.footerSection4Title)}
              </h5>
            </div>

            <div className="foot12-1 flex flex-col">

              {/* ---------> Block-1 <-----------*/}
              {footerData?.footerBlock1status && <>
                <div className="flex foot12-11" onClick={() => setDrop1(!drop1)}>
                  <p>{showTranslatedValue(footerData?.block1_title)}</p>
                  <img
                    src="/images/p9.png"
                    className="footer-drop"
                    style={{ transform: drop1 ? "rotate(90deg)" : "" }}
                    alt=""
                  />
                </div>
                <ul
                  className="dropdown-content"
                  style={{
                    maxHeight: drop1 ? "230px" : "0",
                    marginBottom: drop1 ? "40px" : "0",
                  }}
                >
                  {[1, 2, 3, 4, 5].map((val) => {
                    const subTitle = showTranslatedValue(footerData[`block1_sub_title${val}`]);
                    const subLink = footerData[`block1_sub_link${val}`];
                    return (subTitle && <li key={val}><Link to={subLink} key={val} className="flex items-center gap-2">{getSocialIcon(subTitle)}{subTitle}</Link></li>)
                  })}
                </ul>
              </>}

              {/* ---------> Block-2 <-----------*/}
              {footerData?.footerBlock2status && <>
                <div className="flex foot12-11" onClick={() => setDrop2(!drop2)}>
                  <p>{showTranslatedValue(footerData?.block2_title)}</p>
                  <img
                    src="/images/p9.png"
                    style={{ transform: drop2 ? "rotate(90deg)" : "" }}
                    className="footer-drop"
                    alt=""
                  />
                </div>
                <ul
                  className="dropdown-content"
                  style={{
                    maxHeight: drop2 ? "270px" : "0",
                    marginBottom: drop2 ? "40px" : "0",
                  }}
                >
                  {[1, 2, 3, 4, 5].map((val) => {
                    const subTitle = showTranslatedValue(footerData[`block2_sub_title${val}`]);
                    const subLink = footerData[`block2_sub_link${val}`];
                    return (subTitle && <li key={val}><Link to={subLink} key={val} className="flex items-center gap-2">{getSocialIcon(subTitle)}{subTitle}</Link></li>)
                  })}
                </ul>
              </>}

              {/* ---------> Block-3 <-----------*/}
              {footerData?.footerBlock3status && <>
                <div className="flex foot12-11" onClick={() => setDrop3(!drop3)}>
                  <p>{showTranslatedValue(footerData?.block3_title)}</p>
                  <img
                    src="/images/p9.png"
                    style={{ transform: drop3 ? "rotate(90deg)" : "" }}
                    className="footer-drop"
                    alt=""
                  />
                </div>
                <ul
                  className="dropdown-content"
                  style={{
                    maxHeight: drop3 ? "210px" : "0",

                    marginBottom: drop3 ? "40px" : "0",
                  }}
                >
                  {[1, 2, 3, 4, 5].map((val) => {
                    const subTitle = showTranslatedValue(footerData[`block3_sub_title${val}`]);
                    const subLink = footerData[`block3_sub_link${val}`];
                    return (subTitle && <li key={val}><Link to={subLink} className="flex items-center gap-2">{getSocialIcon(subTitle)}{subTitle}</Link></li>)
                  })}
                </ul>
              </>}

              {/* ---------> Block-4 <-----------*/}

              {footerData?.footerBlock4status && (
                <>
                  <div className="flex foot12-11" id="footer-links" onClick={() => setDrop4(!drop4)}>
                    <p>{showTranslatedValue(footerData?.block4_title)}</p>
                    <img
                      src="/images/p9.png"
                      style={{ transform: drop3 ? "rotate(90deg)" : "" }}
                      className="footer-drop"
                      alt=""
                    />
                  </div>
                  <ul
                    className="dropdown-content"
                    style={{
                      maxHeight: drop4 ? "210px" : "0",

                      marginBottom: drop4 ? "40px" : "0",
                    }}
                  >
                    {[1, 2, 3, 4, 5].map((val) => {
                      const subTitle = showTranslatedValue(footerData[`block4_sub_title${val}`]);
                      const subLink = footerData[`block4_sub_link${val}`];
                      return (subTitle && <li key={val}><Link to={subLink} className="flex items-center gap-2 whitespace-normal !break-words">{getSocialIcon(subTitle)}{subTitle}</Link></li>)
                    })}
                  </ul>
                </>
              )}

            </div>

            {/* <div className="foot13-1 flex flex-col">
              <h5>
                {showTranslatedValue(footerData?.block4_sub_title1)}
              </h5>
            </div> */}

          </div>
          <div className="foot2-0">{showTranslatedValue(footerData?.footerSection4SubTitle)}</div>
        </footer>
      }
    </Wrapper>
  );
};

export default Footer;




const Wrapper = styled.div`
  .home-1012 {
    width: 359px;
    height: 639px;
    background-color: white;
    overflow: hidden;
  }

  .home-10112 {
    width: 250px;
    height: 250px;
    background-color: white;
    overflow: hidden;
  }

  .home-102 {
    width: 30%;
    height: 800px;
    background-color: white;
    overflow: hidden;
  }

  .home-1012 img ,
  .home-10112 img,
  .home-102 img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    background-position: center;
    transform: scale(1);
    transition: all 0.8s;
    filter: brightness(0.85); 
  }

  .home-1012:hover img,
  .home-10112:hover img,
  .home-102:hover img {
    transform: scale(1.3);
  }

  @media (max-width:1026px) {
    .home-10112 {
      bottom: 20px;
      width: 265px;
      height: 279px;
      right: 10%;
      transform: translate(50%);
    }

    .home-1012 {
      width: 223px;
      height: 396px;
    }
  }

  @media (max-width:770px) {
    .home-10112 {
      position: absolute;
      right: 50%;
      transform: translate(50%);
    }

    .home-1012 {
      width: 223px;
      height: 396px;
    }
  }
`
