import requests from "./httpService";

const CartServices = {
  addToCart: async (body) => {
    return requests.post("/cart/add", body);
  },
  getCardById: async (id) => {
    return requests.get(`/cart/${id}`);
  },
  deleteCartItem: async (id) => {
    return requests.delete(`/cart/${id}`);
  },

  deleteManyCartItems: async (body) => {
    return requests.post(`/cart/many`, body);
  },

  updateCartQuntitySingle: async (id, body) => {
    return requests.put(`/cart/update/${id}`, body);
  },
  updateCartQuntityVarints: async (id, body) => {
    return requests.put(`/cart/update/variants/${id}`, body);
  },
  updateCartQuntityMoreVarints: async (body) => {
    return requests.post(`/cart/update/morevariants`, body);
  },
  decreementVarintsQuntity: async (id) => {
    return requests.put(`/cart/decreemnt/variants/${id}`);
  },
  increementVariantsQuntity: async (id) => {
    return requests.put(`/cart/increement/variants/${id}`);
  },

  setCartQuantity: async (id, body) => {
    return requests.post(`/cart/set/quantity/${id}`, body);
  },
};

export default CartServices;
