import requests from "./httpService";

const ProductServices = {
  getProductById: async (id) => {
    return requests.post(`/products/get-product-ById/${id}`);
  },

  getAllProductsFeed: async (
    id,
    limit,
    titleFilter,
    priceFilter,
    stockFilter
  ) => {
    return requests.get(
      `/products/categories/${id}?limit=${limit} &titleFilter=${titleFilter} &priceFilter=${priceFilter} &stockFilter=${stockFilter}`
    );
  },

  getAllProductsFeeds: async (slug, limit, page, filterOption) => {
    return requests.get(
      `/products/category/slug/${slug}?limit=${limit}&filter=${filterOption}&page=${page}`
    );
  },

  getShowingAttributes: async (body) => {
    return requests.get("/attributes/show", body);
  },

  getProductBySluge: async () => {
    return requests.get(`/products/featured`);
  },

  getSearchProducts: async (query) => {
    return requests.get(`/products/search/all?query=${query}`);
  },
  getBasketProduct: async (body) => {
    return requests.post(`/cart/basket/slug`, body);
  },
  updateCountAddProducts: async (slug) => {
    return requests.get(`/products/counts-add/${slug}`);
  },
};

export default ProductServices;
