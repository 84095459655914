import { createSlice } from "@reduxjs/toolkit";

const notifyMessageSlice = createSlice({

    name: "productSlice",

    initialState: {
        message: ""
    },

    reducers: {
        setNotifyMessage: (state, action) => {
            state.message = action.payload;
        },

        setEmptyMessage: (state, action) => {
            state.message = ""
        }
    }
});

export const { setNotifyMessage, setEmptyMessage } = notifyMessageSlice.actions;
export default notifyMessageSlice.reducer;