import React, { useContext, useEffect, useState, useRef } from "react";
import "./header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import $ from "jquery";
import cart from "../../assets/home/cart.svg";
import search from "../../assets/home/search.svg";
import AllSubHeader from "./AllSubHeader";
import closeBtn from "../../assets/slider-arrow/close.svg";
import RightArrow from "../../assets/slider-arrow/right-arrow.svg";
import SearchContext from "../context/SearchContext";
import useTranslation from "../../hooks/useTranslation";
import { BASE_URL_IMAGE } from "../../constants/constants";
import Cart from "../cart/cart";
import useAsync from "../../utils/useAsync/useAsync";
import CatgoryServices from "../../services/categoryServices";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addActiveCategoryID } from "../../Store/reducers/categorySlice";
import Cookies from "js-cookie";
import { MdArrowDropDownCircle } from "react-icons/md";
import { debounce } from "lodash";
import useCurrency from "../../hooks/useCurrency";
import useProductDetails from "../../hooks/useProductDetails";
import { changeScrolling } from "../../Store/reducers/clientSettingSlice";
import { FiPlus } from "react-icons/fi";
import useCart from "../../hooks/useCart";
import { productClearState } from "../../Store/reducers/productScrollSlice";
import { addActiveProductID } from "../../Store/reducers/productSlice";

function AllHeader() {
  const searchState = useContext(SearchContext);
  const { searchSideBar, HandleSearch, cartBar, HandleCart } = searchState;
  const [toggleSideBar, setSidebarToggle] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredChild, setHoveredChild] = useState({});
  const { cartLength } = useCart();
  const [toggleHeader, setToggleHeader] = useState(false);
  const allHeaderItem = useRef();

  const [showSubHeader, setShowSubHeader] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [openShop, setOpenShop] = useState(false);
  const headerData = useSelector((store) => store?.storeSettings?.header);
  const toggleScrolling = useSelector(
    (store) => store?.clientSettings?.toggleScrolling
  );

  const { handleCurrencyChange, defaultCurrency } = useCurrency();
  const { showingCurrency: currencies } = useSelector(
    (store) => store.currency
  );

  const {
    handleLanguageChange,
    showTranslatedValue,
    name: languageName,
  } = useTranslation();

  const { showingLanguages: languages } = useSelector(
    (store) => store.language
  );

  const dispatch = useDispatch();

  const [searchTxt, setSearchTxt] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { cartList } = useProductDetails();

  let currentScroll = window.scrollY;

  const { data, error, loading } = useAsync(
    async () => await CatgoryServices.getShowingCategory()
  );

  function handleEShop() {
    setOpenShop(!openShop);
  }

  if (window.innerWidth <= 1024) {
    if (toggleSideBar) {
      // document.addEventListener("scroll", () => {
      //   return;
      // })
      // document.addEventListener("wheel", () => {
      //   return;
      // })
      // document.addEventListener("touchmove", () => {
      //   return;
      // })
      document.querySelector("body").style.overflowY = "hidden";
    } else {
      document.querySelector("body").style.overflowY = "auto";
    }
  }

  // let deltaY = 0;
  // const handleDelta = (e) => {
  //   deltaY = e.deltaY || 0;
  //   if (deltaY >= 0) {
  //     setToggleHeader(false);
  //   } else {
  //     setToggleHeader(true);
  //   }
  // }
  // document.addEventListener("wheel", handleDelta);

  // let scrollValue = 0;
  // const handleAllHeaderY = (e) => {
  //   if (e.touches[0].clientY < scrollValue) {
  //     // setToggleHeader(true);
  //     // allHeader.current.style.top = "-400px";
  //     setToggleHeader(false)
  //   } else {
  //     setToggleHeader(true)
  //     // allHeader.current.style.top = "0px";
  //   }
  // }

  // document.addEventListener("touchstart", (e) => {
  //   scrollValue = e.touches[0].clientY;
  // });

  // document.addEventListener("touchmove", handleAllHeaderY);

  let prevScrollposition = window.pageYOffset;
  window.onscroll = function () {
    let currentScrollPosition = window.pageYOffset;
    if (prevScrollposition > currentScrollPosition) {
      // document.getElementById("hide-header").style.top = "0";
      // mobileHeader.current.style.transform = "translateY(0)";
      setToggleHeader(false);
    } else {
      // document.getElementById("hide-header").style.top = "-80px"; /* adjust this value to the height of your header */
      // mobileHeader.current.style.transform = "translateY(-400px)";
      setToggleHeader(true);
    }
    prevScrollposition = currentScrollPosition;
  };

  function handleScroll() {
    const currentScrollPos = window.scrollY;
    const isScrollingDown = currentScrollPos > prevScrollPos;

    setShowSubHeader(!isScrollingDown || currentScrollPos < 200);

    setPrevScrollPos(currentScrollPos);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 50) {
        $(".desktop-header").css({ "background-color": "#fff9f2" });
      } else {
        $(".desktop-header").css({ "background-color": "#fff9f2" });
      }
      if ($(window).scrollTop() > 50) {
        $("a").css("color", "#000");
      } else {
        $("a").css("color", "#000");
      }
    });
  }, []);

  const handleSearchTxt = () => {
    if (searchTxt.trim().length > 0) {
      HandleSearch();
      navigate(`/eshop?search=${searchTxt}`);
      setSearchTxt("");
    }
  };

  const handleKeyDown = (event) => {
    if (searchTxt.trim().length > 0 && event.key === "Enter") {
      HandleSearch();
      navigate(`/eshop?search=${searchTxt}`);
      setSearchTxt("");
    }
  };

  const openSidebar = () => {
    setSidebarToggle(!toggleSideBar);
  };

  const handleRedict = async (data) => {
    setSidebarToggle(!toggleSideBar);
    // dispatch(productClearState());
     await CatgoryServices.addTheCountsCategory(data);
    navigate(`/eshop/${data}`);
  };

  const RenderCategories = ({ categories }) => {
    if (!categories || categories.length === 0) {
      return null;
    }
    return (
      <div
        className="e-shop-header-category-grid"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {categories.map((category, i) => {
          return (
            <div className={`e-shop-body-categroy grid-section-${i}`} key={i}>
              <div
                className="header-sub-header cursor-pointer"
                onClick={() => handleRedict(category.slug)}
              >
                {showTranslatedValue(category.name)}
              </div>
              <ul className="categories-list">
                {category.children && category.children.length > 0 && (
                  <RenderChildCategories children={category.children} />
                )}
              </ul>
            </div>
          );
        })}
      </div>
    );
  };

  const RenderChildCategories = ({ children }) => {
    const [hoveredChild, setHoveredChild] = useState(null);
    const [toggleChild, setToggleChild] = useState(false);
    return (
      <>
        {children &&
          children.length > 0 &&
          children.map((child, i) => (
            <>
              <li
                key={i}
                // onMouseEnter={() => { setHoveredChild(child); (child?.children.length > 0 && setToggleChild(true)) }}
                className="relative showHeaderChild headerListItems mb-1"
                // onMouseLeave={() => setHoveredChild(null)}
              >
                <div
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                    }
                  }}
                  className="cursor-pointer flex items-center justify-start gap-2"
                >
                  <span
                    onClick={() => handleRedict(child.slug)}
                    className={`${
                      toggleChild &&
                      (hoveredChild === child ||
                        (hoveredChild &&
                          hoveredChild.parentId === child._id)) &&
                      "underline"
                    }`}
                  >
                    {showTranslatedValue(child.name)}
                  </span>

                  {child.children.length > 0 && (
                    <FiPlus
                      onClick={() => {
                        setHoveredChild(child);
                        setToggleChild(true);
                        toggleChild &&
                          (hoveredChild === child ||
                            (hoveredChild &&
                              hoveredChild.parentId === child._id)) &&
                          setToggleChild(false);
                      }}
                      className={`transition-all duration-200 ease-in-out w-4 h-4 ${
                        toggleChild &&
                        (hoveredChild === child ||
                          (hoveredChild && hoveredChild.parentId === child._id))
                          ? "rotate-45"
                          : ""
                      }`}
                    />
                  )}
                </div>
              </li>
              {child.children.length > 0 &&
                (hoveredChild === child ||
                  (hoveredChild && hoveredChild.parentId === child._id)) && (
                  <div
                    className={`h-full w-[180px] overflow-hidden ${
                      toggleChild &&
                      (hoveredChild === child ||
                        (hoveredChild &&
                          hoveredChild.parentId === child._id)) &&
                      "mb-3"
                    }`}
                  >
                    <ul
                      className={`w-full customScrollbar transition-all duration-300 ease-in-out overflow-x-hidden max-h-[0px] overflow-hidden ${
                        toggleChild &&
                        (hoveredChild === child ||
                          (hoveredChild &&
                            hoveredChild.parentId === child._id)) &&
                        "py-2 !max-h-[120px] overflow-y-scroll pb-4"
                      }`}
                    >
                      {/* <RenderChildCategories children={child.children} /> */}
                      {child.children.map((item, i) => (
                        <li key={i} className="relative headerListItems pb-1">
                          <div
                            onClick={() => handleRedict(item.slug)}
                            className="cursor-pointer transition-all duration-300 ease-in-out hover:underline border-black"
                          >
                            <span>{showTranslatedValue(item.name)}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </>
          ))}
      </>
    );
  };

  const RenderMobileCategories = ({ categories }) => {
    if (!categories || categories.length === 0) {
      return null;
    }
    return (
      <>
        {categories.map((category, i) => (
          <div key={i} className="e-shop-body-categroy mb-2">
            <div
              onClick={() => handleRedict(category.slug)}
              className="header-sub-header-mobile"
            >
              {showTranslatedValue(category?.name)}{" "}
            </div>
            <ul className="categories-list">
              {category.children && category.children.length > 0 && (
                <RenderMobileChildCategories children={category.children} />
              )}
            </ul>
          </div>
        ))}
      </>
    );
  };

  const [showMobileChild, setShowMobileChild] = useState(false);

  const RenderMobileChildCategories = ({ children }) => {
    return (
      <>
        {children &&
          children.length > 0 &&
          children.map((child, i) => (
            <li
              key={i}
              onClick={() => setHoveredChild(child)}
              className="relative"
              // onMouseLeave={() => setHoveredChild(null)}
            >
              <span className="!flex items-center gap-2 mt-2">
                <div
                  onClick={() => handleRedict(child?.slug)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                    }
                  }}
                  className=""
                >
                  <span>{showTranslatedValue(child.name)}</span>
                </div>
                {child.children.length > 0 && (
                  <span
                    className="w-[50px]"
                    onClick={() =>
                      showMobileChild === i
                        ? setShowMobileChild(false)
                        : setShowMobileChild(i)
                    }
                  >
                    <MdArrowDropDownCircle
                      className={`text-xl ${
                        showMobileChild === i && "rotate-180"
                      }`}
                    />
                  </span>
                )}
              </span>

              {child.children.length > 0 && showMobileChild === i && (
                <ul
                  className={`categories-list px-3 py-2 ${
                    showMobileChild !== i && "hidden"
                  }`}
                >
                  {child.children.map((item, idx) => (
                    <li key={idx} className="pb-1">
                      <div onClick={() => handleRedict(item.slug)}>
                        {showTranslatedValue(item.name)}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
      </>
    );
  };
  // useEffect(() => {
  //   setShowSubHeader(false);
  //   setTimeout(() => {
  //     setShowSubHeader(true);
  //   }, 200)

  // }, [showSubHeader])

  if (!headerData) {
    return;
  }

  return (
    <Wrapper>
      {showSubHeader && <AllSubHeader />}
      <header
        className="desktop-header"
        style={{
          backgroundColor: "#fff9f2",
          top: showSubHeader ? "56px" : "0",
        }}
      >
        <div className="header-box">
          <Link to="/">
            <div
              className="logo"
              style={{
                backgroundImage: `url(${BASE_URL_IMAGE}${headerData?.logo[1]})`,
              }}
            ></div>
          </Link>
          <ul className="text-none header-item">
            <li className="header-list">
              <Link className="relative a-tag eshop" style={{ color: "#000" }}>
                {showTranslatedValue(headerData?.headerMenu1)}
              </Link>

              {/* ------------> other page Header Desktop View <---------- */}

              {/* ------------> 1.other page Header Categories Section <---------- */}
              <div
                className="e-shop-container"
                onMouseEnter={() => {
                  dispatch(changeScrolling(true));
                }}
                onMouseLeave={() => {
                  dispatch(changeScrolling(false));
                }}
              >
                <div className="e-shop-body items-stretch">
                  <RenderCategories categories={data[0]?.children} />
                  {/* {!loading && (
                    <RenderCategories categories={data[0]?.children} />
                  )} */}

                  {/* ------------> 2. other page Header Products Section <---------- */}
                  <div className="header-products">
                    {headerData?.showHeaderBanner1 && (
                      <div style={{ overflow: "hidden" }}>
                        <h6 className="header-sub-header">
                          {showTranslatedValue(headerData?.headerBannerText1)}
                        </h6>
                        <Link
                          to={`eshop${headerData?.headerBanner1Link}`}
                          className="header-img"
                          style={{ marginBottom: "20px" }}
                        >
                          <img
                            src={`${BASE_URL_IMAGE}${headerData?.headerBannerImg1}`}
                            alt=""
                          />
                        </Link>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: showTranslatedValue(
                              headerData?.headerBannerDesc1
                            ),
                          }}
                        ></div>
                      </div>
                    )}
                    {headerData?.showHeaderBanner2 && (
                      <div style={{ overflow: "hidden" }}>
                        <h6 className="header-sub-header">
                          {showTranslatedValue(headerData?.headerBannerText2)}
                        </h6>
                        <Link
                          to={`eshop${headerData?.headerBanner2Link}`}
                          className="header-img"
                          style={{ marginBottom: "20px" }}
                        >
                          <img
                            src={`${BASE_URL_IMAGE}${headerData?.headerBannerImg2}`}
                            alt=""
                          />
                        </Link>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: showTranslatedValue(
                              headerData?.headerBannerDesc2
                            ),
                          }}
                        ></div>
                      </div>
                    )}

                    {headerData?.showHeaderBanner3 && (
                      <div style={{ overflow: "hidden" }}>
                        <h6 className="header-sub-header">
                          {showTranslatedValue(headerData?.headerBannerText3)}
                        </h6>
                        <Link
                          to={`eshop${headerData?.headerBanner3Link}`}
                          className="header-img"
                          style={{ marginBottom: "20px" }}
                        >
                          <img
                            src={`${BASE_URL_IMAGE}${headerData?.headerBannerImg3}`}
                            alt=""
                          />
                        </Link>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{
                            __html: showTranslatedValue(
                              headerData?.headerBannerDesc3
                            ),
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
            <li className="header-list">
              <Link
                className="relative a-tag"
                style={{ color: "#000" }}
                to={headerData?.headerMenu2Link}
              >
                {showTranslatedValue(headerData?.headerMenu2)}
              </Link>
            </li>
            <li className="header-list">
              <Link
                className="a-tag"
                style={{ color: "#000" }}
                to={headerData?.headerMenu3Link}
              >
                {showTranslatedValue(headerData?.headerMenu3)}
              </Link>
            </li>
            <li className="header-list">
              <Link
                className="a-tag"
                style={{ color: "#000" }}
                to={headerData?.headerMenu4Link}
              >
                {showTranslatedValue(headerData?.headerMenu4)}
              </Link>
            </li>
            <li className="header-list">
              <Link
                className="a-tag"
                style={{ color: "#000" }}
                to={headerData?.headerMenu5Link}
              >
                {showTranslatedValue(headerData?.headerMenu5)}
              </Link>
            </li>
          </ul>
        </div>
      </header>

      {/* ----------> Eshop Header Mobile View <------------ */}

      <MobileHeaderWapper>
        <header
          className={`mobile-header ${toggleHeader && "translate-y-[-7rem]"}`}
          ref={allHeaderItem}
        >
          <div className="header-box">
            <button className="bras-btn" onClick={openSidebar}>
              <img src="/images/p31.png" alt="" />
            </button>
            <Link to="/">
              <div className="logo"></div>
            </Link>
            <div
              className="header-item"
              style={toggleSideBar ? { left: 0 } : { left: "-1600px" }}
            >
              <div className="close-icon" onClick={openSidebar}></div>
              <div className="header-list">
                <div className="mobile-item">
                  <Link
                    style={{
                      transform: toggleSideBar
                        ? "translate(0px, 0px)"
                        : "translateY(100%)",
                      transitionDelay: "0.1s",
                      color: toggleSideBar && "#FFF !important",
                    }}
                    onClick={() => handleEShop()}
                  >
                    {showTranslatedValue(headerData?.headerMenu1)}
                  </Link>
                  <div
                    className="header-sub-list"
                    style={{
                      height: openShop ? "23rem" : "0",
                      transition: "all 0.5s",
                    }}
                  >
                    {/* Mobile Category mapped */}
                    <div className="e-shop-body !max-h-[23rem] overflow-y-scroll customScrollbar pb-2">
                      <RenderMobileCategories categories={data[0]?.children} />

                      {/* {!loading && (
                        <RenderMobileCategories categories={data[0]?.children} />
                      )} */}

                      {/* {data[0]?.children?.map((category) => <div key={category?._id} className="e-shop-body-categroy ml-[0.6rem]">
                      <div to={`/eshop/${category._id}`} className="header-sub-header-mobile" onClick={openSidebar}>{showTranslatedValue(category?.name)} </div>
                      <ul className="categories-list ml-[1rem]">
                        {category?.children?.map((subCategory) => <li key={subCategory?._id}>
                          <Link to={`/eshop/${subCategory?._id}`} onClick={openSidebar}>
                            {showTranslatedValue(subCategory?.name)}
                          </Link>
                          <ul className="categories-list ml-[0.5rem]">
                            {subCategory?.children?.map((item) => <li key={item?._id}>
                              <Link to={`/eshop/${item?._id}`} onClick={openSidebar}>
                                {showTranslatedValue(item?.name)}
                              </Link>
                            </li>)}
                          </ul>
                        </li>)}
                      </ul>
                    </div>)} */}
                    </div>
                  </div>
                </div>

                <div className="mobile-item">
                  <Link
                    style={{
                      transform: toggleSideBar
                        ? "translate(0px, 0px)"
                        : "translateY(100%)",
                      transitionDelay: "0.5s",
                      color: toggleSideBar && "#FFF !important",
                    }}
                    to={headerData?.headerMenu2Link}
                    onClick={openSidebar}
                  >
                    {showTranslatedValue(headerData?.headerMenu2)}
                  </Link>
                </div>
                <div className="mobile-item">
                  <Link
                    style={{
                      transform: toggleSideBar
                        ? "translate(0px, 0px)"
                        : "translateY(100%)",
                      transitionDelay: "0.7s",
                      color: toggleSideBar && "#FFF !important",
                    }}
                    to={headerData?.headerMenu3Link}
                    onClick={openSidebar}
                  >
                    {showTranslatedValue(headerData?.headerMenu3)}
                  </Link>
                </div>
                <div className="mobile-item">
                  <Link
                    style={{
                      transform: toggleSideBar
                        ? "translate(0px, 0px)"
                        : "translateY(100%)",
                      transitionDelay: "0.9s",
                      color: toggleSideBar && "#FFF !important",
                    }}
                    to={headerData?.headerMenu4Link}
                    onClick={openSidebar}
                  >
                    {showTranslatedValue(headerData?.headerMenu4)}
                  </Link>
                </div>
                <div className="mobile-item">
                  <Link
                    style={{
                      transform: toggleSideBar
                        ? "translate(0px, 0px)"
                        : "translateY(100%)",
                      transitionDelay: "1.1s",
                      color: toggleSideBar && "#FFF !important",
                    }}
                    to={headerData?.headerMenu5Link}
                    onClick={openSidebar}
                  >
                    {showTranslatedValue(headerData?.headerMenu5)}
                  </Link>
                </div>
                <div className="mobile-item">
                  <Link
                    style={{
                      transform: toggleSideBar
                        ? "translate(0px, 0px)"
                        : "translateY(100%)",
                      transitionDelay: "1.1s",
                      color: toggleSideBar && "#FFF !important",
                    }}
                    to="/my-account"
                    onClick={openSidebar}
                  >
                    My Account
                  </Link>
                </div>
              </div>
              <div className="responsive-cart !justify-between">
                <div className="flex justify-between items-center gap-3 w-full">
                  <div className="language">
                    <p className="language-text-" style={{ color: "#fff" }}>
                      {languageName}
                    </p>
                    <ul className="language-list2">
                      {languages?.map((eachLang) => (
                        <li
                          key={eachLang._id}
                          onClick={() =>
                            handleLanguageChange({
                              lang: eachLang?.iso_code,
                              name: eachLang?.name?.toUpperCase(),
                            })
                          }
                        >
                          {eachLang?.name?.toUpperCase()}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="currency">
                    <p className="currency-text-" style={{ color: "#fff" }}>
                      {defaultCurrency}
                    </p>
                    <ul className="currency-list2">
                      {currencies?.map((eachCurr) => (
                        <li
                          key={eachCurr._id}
                          onClick={() =>
                            handleCurrencyChange({
                              code: eachCurr?.code,
                              symbol: eachCurr.symbol,
                            })
                          }
                        >{`${eachCurr?.name?.toUpperCase()} (${eachCurr?.symbol?.toUpperCase()})`}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <h6 className="m-0 currency-name">GBP</h6>
                <h6 className="m-0 currency-name">Usd</h6> */}
                {/* <Link to="/my-account"
                  onClick={openSidebar}
                >
                  <img
                    src="/images/p3.png"
                    style={{ objectFit: "cover", marginLeft: "20px" }}
                    alt=""
                  />
                </Link> */}
              </div>
            </div>
            <div className="home11-nav03 flex relative" style={{ gap: "18px" }}>
              <button onClick={HandleSearch}>
                <img src={search} alt="" />
              </button>
              <button onClick={HandleCart}>
                <img src={cart} alt="" />
                <div className="mobile-cart-qty-circle-home">
                  <span>{cartLength}</span>
                </div>
              </button>
            </div>
          </div>
        </header>
      </MobileHeaderWapper>

      <SearchWrapper>
        <div
          className="search-box"
          style={searchSideBar ? { right: 0 } : { right: "-110%" }}
        >
          <button className="close-img" onClick={HandleSearch}>
            <img src={closeBtn} alt="" />
          </button>

          <div className="search-item-container">
            <div className="flex">
              <input
                type="text"
                className="search-input"
                placeholder="Search"
                value={searchTxt}
                onChange={(e) => setSearchTxt(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button
                className="right"
                onClick={handleSearchTxt}
                disabled={searchTxt.trim().length === 0}
              >
                <img src={RightArrow} alt="" />
              </button>
            </div>
          </div>
        </div>
      </SearchWrapper>
      <Cart cartBar={cartBar} HandleCart={HandleCart} />
    </Wrapper>
  );
}

export default AllHeader;

const Wrapper = styled.div`
  .showHeaderChild {
    font-family: "TheinhardtPan-Regular", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #000 !important;
    font-weight: 500;
  }

  .showHeaderChild:hover {
    text-decoration: underline;
  }
`;

const MobileHeaderWapper = styled.div`
  .mobile-item a {
    color: #fff9f2 !important;
  }

  .close-img img {
    height: 37px;
    width: 37px;
  }

  .close-img {
    right: 26px;
    position: absolute;
    top: 26px;
  }
`;

const SearchWrapper = styled.div`
  @media (max-width: 497px) {
    .close-img img {
      height: 37px;
      width: 37px;
    }

    .close-img {
      right: 26px;
      position: absolute;
      top: 26px;
    }
  }
`;
