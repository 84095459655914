import { useEffect, useState } from "react";
import { BASE_URL_IMAGE } from "../../constants/constants";
import useProductDetails from "../../hooks/useProductDetails";
import useTranslation from "../../hooks/useTranslation";
import useCurrency from "../../hooks/useCurrency";
import cartClose from "../../assets/close-cart.svg";
import "../../../src/components/pages/css/cart.css";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TfiClose } from "react-icons/tfi";
import useCart from "../../hooks/useCart";

const Cart = ({ cartBar, HandleCart }) => {

  const { totalPrice, carts, cartLength, outOfStock, deleteAllItems } = useCart();

  const { showTranslatedValue } = useTranslation();
  const { symbol, handleChangeValue } = useCurrency();
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate(carts.length > 0 ? "/cart" : "#");
    HandleCart();
  };

  const location = useLocation();

  if (location.pathname === "/cart") {
    return
  }

  return (
    <Wrapper>
      {cartBar && <div className="cart-backdrop" onClick={HandleCart}></div>}
      <div className={`cart-sidebar ${cartBar ? "cart-hide" : "cart-show"}`}>
        {/* -----> Cart Header <------- */}
        <div className="cart-header">
          <div className="cart-title-and-circle-span">
            <h6 className="cart-title">Cart</h6>
            <span className="cart-qty-circle">{cartLength}</span>
          </div>
          <button className="cart-close" onClick={HandleCart}>
            Close
          </button>
        </div>

        {/* -----> Cart Body <------- */}
        <ul className="cart-body customScrollbar mr-2">
          {carts?.map((item, i) => {

            return (
              <>
                <li className="cart-single-product-grid" key={i}>
                  <div className="cart-img">
                    <img
                      src={BASE_URL_IMAGE + item?.cart?.image}
                      alt={item?.cart?.image}
                    />
                  </div>

                  <div className="cart-info-content">
                    <h2 title={item?.cart?.outOfStockCart && "Out of Stock"} className={item?.cart?.outOfStockCart && "!text-red-500"}>{showTranslatedValue(item?.cart?.title)}</h2>
                    <div className="flex flex-col items-start justify-start">
                      <div className="flex items-center justify-start cart-title-weight h-6">
                        <p className="!text-[0.9rem]">{`Weight: `}</p>
                        <h3 className="text-xl" translate="no">{`${parseFloat(item?.cart?.totalWeight).toFixed(2) ?? "0"
                          }g `}</h3>
                      </div>
                      <div className="flex items-center justify-start cart-title-weight h-6">
                        <p className="!text-[0.9rem]">{`Quantity: `}</p>
                        <h3 translate="no">{`x ${item?.cart?.totalQuantity ?? "0"}`}</h3>
                      </div>
                    </div>
                  </div>

                  <div className="cart-price-and-close-btn">
                    <TfiClose
                      onClick={() => deleteAllItems(item?.cart?.eachCart)}
                    />
                    <h2 className="sm:pb-3 lg:pb-1"
                      translate="no"
                    >

                      {`${symbol} ${handleChangeValue(
                        item?.cart?.totalPrice
                      )}`}
                    </h2>
                  </div>
                </li>
                <div className="h-[0.5px] w-[100%] my-3 bg-slate-200"></div>
              </>
            );
          })}
        </ul>

        {/* -----> Cart Footer <------- */}
        <div className="cart-footer">
          <div className="w-full flex justify-center items-center">
            <p className="cart-offer-text !text-[0.8rem] md:!px-6 !px-2 md:!w-[80%] !w-[100%] !border-none">
              {outOfStock ? "Some items in your Cart are out of stock. If you proceed, the entire order will ship within 45 days." : "All items will be shipped in 2 days."}
            </p>
          </div>
          <div className="cart-proceed-box border-t">
            <div className="flex justify-between">
              <p className="cart-label-left">Shipping</p>
              <p className="cart-label-right">Calculated in the next step</p>
            </div>
            <div className="flex justify-between">
              <p className="cart-label-left">Total</p>
              <p className="cart-label-right">
                {symbol}
                <span translate="no">
                  {handleChangeValue(totalPrice)}
                </span>
              </p>
            </div>
            <div
              className={`sign-in-btn mt-[16px] w-[100%] ${carts.length === 0 ? "disabled" : ""
                }`}
              style={{
                textTransform: "inherit",
                opacity: `${carts.length === 0 ? "0.3" : "1"}`,
              }}
              onClick={() => handleRedirect()}
            >
              Order — {symbol}
              <span translate="no">
                {handleChangeValue(totalPrice)}
              </span>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Cart;

const Wrapper = styled.div`
  .cart-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 600;
    background-color: #000;
    opacity: 0;
  }

  .cart-sidebar {
    display: block;
    position: fixed;
    min-width: 500px;
    height: 100vh;
    right: 0;
    bottom: 0;
    top: 0;
    // transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
    background-color: #fff9f2;
    border-left: 1px solid #d9d9d9;
    padding-top: 0px;
    z-index: 1000;
    display: grid;
    grid-template-rows: 15vh 57vh 26vh;
    row-gap: 1vh;
  }

  .cart-hide {
    // right: 0;
    transform: translateX(0px);

  }

  .cart-show {
    // right: -850px;
    transform: translateX(850px);
  }

  /* -----> Cart Header <-------- */
  .cart-header {
    grid-row: 1/2;
    width: 100%;
    height: 100%;
    padding: 75px 30px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .cart-title-and-circle-span {
    position: relative;
    display: flex;
    gap: 1rem;
    transition: none !important;
  }

  .cart-title {
    font-family: "Saol";
    font-style: italic;
    font-weight: 200;
    font-size: 40px;
    line-height: 46px;
    text-transform: uppercase;
  }

  .cart-qty-circle {
    position: absolute;
    right: -44px;
    text-align: center;
    top: 5px;
    width: 28px;

    padding: 5px 3px 5px;
    border-radius: 50%;
    background-color: #a03907;
    color: #fff;
    font-size: 13px;
    line-height: 15px;
    font-family: "TheinhardtPan-Regular", sans-serif;
    transition: none !important;

  }

  /* -----> Cart Footer <-------- */
  .cart-body {
    grid-row: 2/3;
    width: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 0.5rem 1.5rem;
    /* height: calc(100vh - 15vh - 1vh - 27vh); */
    transition: none !important;

  }

  .cart-body::-webkit-scrollbar {
    width: 0px;
  }

  .cart-single-product-grid {
    display: grid;
    grid-template-columns: 6rem 1fr 6rem;
    column-gap: 1rem;
  }

  .cart-img {
    height: 6rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0.4rem;
    overflow: hidden;
    cursor: pointer;
    /* background-color: #fff9f2; */
  }

  .cart-img img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    // transition: all 2s cubic-bezier(0.25, 1, 0.5, 1);
    /* mix-blend-mode: darken; */
  }

  .cart-img:hover img {
    transform: scale(1.5);
  }

  .cart-info-content {
    padding: 0.4rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
  }

  .cart-info-content h2 {
    color: #000;
    font-family: "Theinhardt Pan", serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
  }

  .cart-title-weight p {
    color: #000;
    font-family: "TheinhardtPan-Regular", sans-serif;
    font-size: 1rem;
    font-weight: 500;
    margin-right: 0.4rem;
  }

  .cart-title-weight h3 {
    color: #000;
    font-family: "TheinhardtPan-Regular", sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    margin-right: 0.4rem;
  }

  .cart-title-weight {
    display: flex;
  }

  .cart-price-and-close-btn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0.3rem 0rem;
    padding-right: 1rem;
  }

  .cart-price-and-close-btn h2 {
    color: #000;
    font-family: "TheinhardtPan-Regular", sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    white-space: nowrap;
  }

  .cart-price-and-close-btn svg {
    font-size: 1.4rem;
    color: #898888;
    cursor: pointer;
  }

  .cart-price-and-close-btn svg:hover {
    font-size: 1.4rem;
    color: #a03907;
  }

  /* -----> Cart Footer <-------- */
  .cart-footer {
    grid-row: 3/-1;
    width: 100%;
    height: 100%;
    background-color: #fff;
  }

  .sign-in-btn {
    background-color: #000;
    color: #fff !important;
    border: 1px solid transparent;
    cursor: pointer;
    padding: 13px 20px;
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-family: "Theinhardt Pan", sans-serif;
    font-size: 15px;
    font-weight: 200;
    transition: all 0.5s;
  }

  .sign-in-btn:hover {
    border: 1px solid #000;
    color: #000 !important;
    background-color: transparent;
  }

  .cart-proceed-box {
    display: flex;
    flex-direction: column;
  }

  .cart-proceed-box a {
    text-align: center;
  }

  @media (max-width: 1181px) {
    .cart-info-content h2 {
      font-size: 1.1rem;
    }

    .cart-title-weight p {
      font-size: 0.9rem;
    }

    .cart-price-and-close-btn h2 {
      font-size: 1rem;
      line-height: 1.1rem;
    }
  }

  @media (max-width: 1025px) {
    .cart-sidebar {
      height: 100vh;
      display: grid;
      grid-template-rows: 15vh 55vh 28vh;
      row-gap: 1vh;
    }

    .cart-header {
      padding: 50px 30px 10px;
    }

    .cart-proceed-box {
      padding: 25px 40px 30px;
    }
  }

  @media (max-width: 498px) {
    .cart-info-content h2 {
      font-size: 1.1rem !important;
      line-height: 1.2rem;
    }

    .cart-price-and-close-btn h2 {
      font-size: 1rem !important;
      line-height: 1.1rem;
    }
  }

  @media (max-width: 431px) {
    .cart-sidebar {
      min-width: 100%;
      height: 100vh;

      display: grid;
      grid-template-rows: 13vh 57vh 28vh;
      row-gap: 1vh;
    }

    .cart-header {
      padding: 2rem 1.3rem 10px;
    }

    .cart-proceed-box {
      padding: 10px 1.3rem 30px;
    }

    .cart-body {
      padding: 0.5rem 1.4rem;
    }

    .cart-single-product-grid {
      grid-template-columns: 5rem 1fr 6rem;
      column-gap: 1rem;
    }

    .cart-img {
      height: 5rem;
    }

    .cart-info-content {
      padding: 0rem 0rem;
      gap: 0.4rem;
    }

    .cart-price-and-close-btn {
      padding: 0rem 0rem;
      padding-right: 0rem;
    }
  }

  @media (max-width: 376px) {
    .cart-header {
      padding: 2rem 0.8rem 10px;
    }

    .cart-body {
      padding: 0.5rem 0.8rem;
    }

    .cart-proceed-box {
      padding: 10px 0.8rem 30px;
    }
  }
`;
