import { createSlice } from "@reduxjs/toolkit";

const couponSlice = createSlice({

    name: "coupon",

    initialState: {
        name: "",
    },

    reducers: {

        setCoupon: (state, action) => {
            state.name = action.payload;
        },

    }

});

export const { setCoupon } = couponSlice.actions;
export default couponSlice.reducer;