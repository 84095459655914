import React, { useEffect, useState } from "react";
import "./css/login.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import Cookies from "js-cookie";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { notifyError } from "../../utils/toast";
import SEO from "../../utils/SEO/SEO";

function ResetPassword() {

    const { token } = useParams();
    const navigate = useNavigate();

    const {
        handleResetPassword
    } = useAuth();

    const Navigate = useNavigate();

    useEffect(() => {
        const userInfo = JSON.parse(Cookies.get("userInfo") || null);

        if (userInfo) {
            Navigate("/my-account");
        }

    }, []);

    if (!token) {
        notifyError("Token Not Found");
        navigate("/forget-password");
    }


    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const handleMouseDown = () => {
        setShowPassword(true);
    };

    const handleMouseUp = () => {
        setShowPassword(false);
    };

    return (
        <>
      <SEO title={"Reset Password"}/>

            <div className="login-page">
                <div className="w-[350px] md:w-[600px]">
                    {/* -------> Login Form <------- */}
                    <form onSubmit={(e) => handleResetPassword(e, token)}>
                        <h6 className="login-title mb-[40px]">Reset Password</h6>
                        <div className="flex flex-col gap-[16px] mb-[16px]">
                            <div className="relative">
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    className="form-control mb-2"
                                    placeholder="New Password"
                                    required
                                />
                                <div
                                    className="absolute top-[0.7rem] right-[1rem]"
                                    // onClick={() => {
                                    //   setShowPassword(!showPassword);
                                    // }}
                                    onClick={() => setShowPassword(!showPassword)}

                                >
                                    {!showPassword ? (
                                        <FaEye />
                                    ) : (
                                        <FaEyeSlash />
                                    )}
                                </div>
                            </div>

                            <div className="relative">
                                <input
                                    type={showPassword2 ? "text" : "password"}
                                    name="confirmPassword"
                                    className="form-control"
                                    placeholder="Confirm New Password"
                                    required
                                />
                                <div
                                    className="absolute top-[0.7rem] right-[1rem]"
                                    // onClick={() => {
                                    //   setShowPassword(!showPassword);
                                    // }}
                                    onClick={() => setShowPassword2(!showPassword2)}
                                >
                                    {!showPassword2 ? (
                                        <FaEye />
                                    ) : (
                                        <FaEyeSlash />
                                    )}
                                </div>
                            </div>

                        </div>
                        <div className="flex items-center">
                            <button className="sign-in-btn me-[24px]">Reset</button>
                            {/* <Link to="/login" className="forget-pass hover:underline">If you know password?</Link> */}
                        </div>
                    </form>
                </div>
            </div>

        </>
    );
}

export default ResetPassword;
