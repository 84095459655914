import { configureStore } from "@reduxjs/toolkit";
import storeSettings from "./reducers/storeSettings";
import languageReducer from "./reducers/languageSlice";
import currencyReducer from "./reducers/currencySlice";
import authReducer from "./reducers/authSlice";
import productSlice from "./reducers/productSlice";
import categorySlice from "./reducers/categorySlice";
import notifyMessageSlice from "./reducers/notifySlice";
import clientSettingSlice from "./reducers/clientSettingSlice";
import couponSlice from "./reducers/couponSlice";
import productScrollSlice from "./reducers/productScrollSlice";
import cartSlice from "./reducers/cartSlice";


const store = configureStore({
    reducer: {
        storeSettings: storeSettings,
        language: languageReducer,
        currency: currencyReducer,
        auth: authReducer,
        activeProductID: productSlice,
        addActiveCategoryID: categorySlice,
        notifyMessage: notifyMessageSlice,
        clientSettings: clientSettingSlice,
        coupon: couponSlice,
        productScroll: productScrollSlice,
        cart: cartSlice,
    }
});

export default store;