import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const userInfo = JSON.parse(Cookies.get("userInfo") || null);

const productScrollSlice = createSlice({
  name: "auth",
  initialState: {
    closeDetails: false,
    productOne: [],
    productTwo: [],
    productThree: [],
    featheredProduct: [],
    ProductPage: 2,
    ProductLimit: 20,
    productAttribute: [],
    currRoute: "",
    wislistID:[]
  },
  reducers: {
    scrollEshop: (state, action) => {
      state.closeDetails = action.payload;
    },
    productOneReducer: (state, action) => {
      state.productOne = action.payload;
    },
    productTwoReducer: (state, action) => {
      state.productTwo = action.payload;
    },
    productThreeReducer: (state, action) => {
      state.productThree = [...state.productThree, ...action.payload];
    },

    productFeaturedProductReducer: (state, action) => {
      state.featheredProduct = action.payload;
    },
    productPageReducer: (state, action) => {
      state.ProductPage = action.payload;
    },
    productLimitReducer: (state, action) => {
      state.ProductLimit = action.payload;
    },
    productAttributes: (state, action) => {
      state.productAttribute = action.payload;
    },
    currentRoutes: (state, action) => {
      state.currRoute = action.payload;
    },
    wislistProductID: (state, action) => {
      state.wislistID = action.payload;
    },
   
    productClearState: (state, action) => {
      return {
        productOne: [],
        productTwo: [],
        productThree: [],
        featheredProduct: [],
        ProductPage: 2,
        ProductLimit: 20,
      };
    },
  },
});

export const {
  scrollEshop,
  productOneReducer,
  productTwoReducer,
  productThreeReducer,
  productFeaturedProductReducer,
  productLimitReducer,
  productPageReducer,
  productClearState,
  productAttributes,
  currentRoutes,wislistProductID
} = productScrollSlice.actions;
export default productScrollSlice.reducer;
