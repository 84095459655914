import requests from "./httpService";

const StoreSettingService = {

  getStoreCustomizationSetting: async () => {
    return requests.get("/setting/store/customization/all");

    // console.log("StoreCustomizationSetting api called !!")
  },

};

export default StoreSettingService;