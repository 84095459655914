import { Helmet } from "react-helmet";
import favicon from "../../assets/favicon.png";
import { BASE_URL_IMAGE } from "../../constants/constants";

const SEO = ({ title, description, metaContent }) => {
  return (
    <Helmet>
      {/* <html lang="en" /> */}
      <title> {title} | Alma Jewellery </title>
      <meta name="description" content={description} />
      <link rel="shortcut icon" href={favicon} type="image/x-icon" />

      {metaContent?.title && <meta name="title" content={metaContent.title} />}
      {metaContent?.description && (
        <meta name="description" content={metaContent.description} />
      )}

      {metaContent?.title && (
        <meta property="og:title" content={metaContent.title} />
      )}
      {metaContent?.description && (
        <meta property="og:description" content={metaContent.description} />
      )}
      {metaContent?.image && (
        <meta
          property="og:image"
          content={BASE_URL_IMAGE + metaContent.image}
        />
      )}
      {metaContent?.keywords && (
        <meta
          property="og:keywords"
          content={JSON.parse(metaContent.keywords || "[]")?.join(",")}
        />
      )}

      {metaContent?.title && (
        <meta name="twitter:title" content={metaContent.title} />
      )}
      {metaContent?.description && (
        <meta name="twitter:description" content={metaContent.description} />
      )}
      {metaContent?.image && (
        <meta
          name="twitter:image"
          content={BASE_URL_IMAGE + metaContent.image}
        />
      )}
    </Helmet>
  );
};

export default SEO;
