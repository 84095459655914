import { lazy } from "react";
import ResetPassword from "../components/pages/ResetPassword";
import NotFound from "../components/pages/NotFound";
import UnSubscribeNewsletter from "../components/pages/UnsubscribeNewsletter";

const LoginPage = lazy(() => import("../components/pages/LoginPage"));
const EmailVerification = lazy(() => import("../components/pages/EmailVerification"));
const ForgotPassword = lazy(() => import("../components/pages/ForgotPassword"));

const Home = lazy(() => import("../components/pages/Home"));
const About = lazy(() => import("../components/pages/About"));
const Eshop = lazy(() => import("../components/pages/Eshop"));
const Blog = lazy(() => import("../components/pages/Blog"));
const Customization = lazy(() => import("../components/pages/Customization"));
const TheShop = lazy(() => import("../components/pages/TheShop"));
const Creators = lazy(() => import("../components/pages/aboutPages/Creators"));
const WorkShop = lazy(() => import("../components/pages/aboutPages/WorkShop"));
const Maintenance = lazy(() =>
  import("../components/pages/aboutPages/Maintenance")
);
const Warranty = lazy(() => import("../components/pages/aboutPages/Warranty"));
const BlogDetail = lazy(() => import("../components/pages/BlogDetail"));
const ProductDetail = lazy(() => import("../components/pages/ProductDetail"));
const AccountPage = lazy(() => import("../components/pages/AccountPage"));
const InformationPage = lazy(() => import("../components/pages/account/Info"));
const AddAddress = lazy(() => import("../components/pages/account/AddAddress"));
const History = lazy(() => import("../components/pages/account/History"));
const WishList = lazy(() => import("../components/pages/account/WishList"));
const GDPR = lazy(() => import("../components/pages/account/GDPR/GDPR"));
const Alert = lazy(() => import("../components/pages/account/Alert"));
// const Points = lazy(() => import("../components/pages/account/Points"));
const LookBook = lazy(() => import("../components/pages/LookBook"));
const ContactUs = lazy(() => import("../components/pages/Contact"));
const TermsPage = lazy(() => import("../components/pages/TermsPage"));
const FaqPage = lazy(() => import("../components/pages/FaqPage"));
const ReturnPage = lazy(() => import("../components/pages/ReturnPage"));
const AskReturnPage = lazy(() => import("../components/pages/AskReturnPage"));
const PolicyPage = lazy(() => import("../components/pages/PolicyPage"));
const CartPage = lazy(() => import("../components/pages/CartPage"));
const CheckOut = lazy(() => import("../components/pages/CheckOut"));
const SearchPage = lazy(() => import("../components/pages/SearchPage"));

export const privateRoutes = [
  { path: "/about", element: <About /> },
  { path: "/about/creators", element: <Creators /> },
  { path: "/about/workshop", element: <WorkShop /> },
  { path: "/about/maintenance", element: <Maintenance /> },
  { path: "/about/warranty", element: <Warranty /> },
  { path: "/eshop", element: <SearchPage /> },
  { path: "/eshop/:id", element: <Eshop /> },
  // { path: "/eshop/:id1/:id2", element: <Eshop /> },
  // { path: "/eshop/:id1/:id2/:id3", element: <Eshop /> },
  { path: "/eshop/detail/:id1/:id2", element: <ProductDetail /> },
  // { path: "/eshop/detail/*", element: <ProductDetail /> },
  { path: "/look-book", element: <LookBook /> },
  { path: "/blog", element: <Blog /> },
  { path: "/my-account", element: <AccountPage /> },
  { path: "/information", element: <InformationPage /> },
  { path: "/add-address", element: <AddAddress /> },
  { path: "/history", element: <History /> },
  { path: "/wishlist", element: <WishList /> },
  { path: "/alert", element: <Alert /> },
  { path: "/gdpr", element: <GDPR /> },
  // { path: "/points", element: <Points /> },
  { path: "/blog/:slug/", element: <BlogDetail /> },
  { path: "/customization", element: <Customization /> },
  { path: "/the-shop", element: <TheShop /> },
  { path: "/product/detail", element: <Eshop /> },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/terms-and-conditions", element: <TermsPage /> },
  { path: "/faq", element: <FaqPage /> },
  { path: "/delivery-returns", element: <ReturnPage /> },
  { path: "/ask-returns", element: <AskReturnPage /> },
  { path: "/cookies-policy", element: <PolicyPage /> },
  { path: "/cart", element: <CartPage /> },
  { path: "/cart/checkout", element: <CheckOut /> },
];

export const routes = [
  { path: "/", element: <Home /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password/:token", element: <ResetPassword /> },
  { path: "/user/email-verification", element: <EmailVerification /> },
  { path: "/unsubscribe/:token", element: <UnSubscribeNewsletter /> },
  { path: "*", element: <NotFound /> },
];
