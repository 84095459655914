import requests from "./httpService";

const CustomerNewsletterServices = {

    addCustomerToNewsletter: async(body) => {
        return await requests.post('/newsletter/customer/create', body);
    }

}

export default CustomerNewsletterServices;