import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const userInfo = JSON.parse(Cookies.get("userInfo") || null);

const authSlice = createSlice({
    
    name: "auth",

    initialState: {
        userInfo: (userInfo || null)
    },

    reducers: {

        setUser: (state,action) => {
            state.userInfo = action.payload
        },

        removeUser: (state, action) => {
            state.userInfo = null
        }

    }

});

export const { setUser , removeUser } = authSlice.actions;
export default authSlice.reducer;