import React, { useEffect, useState } from "react";
import CartServices from "../services/cartServices";
import { useDispatch, useSelector } from "react-redux";
import { setNotifyMessage } from "../Store/reducers/notifySlice";
import { notifyError } from "../utils/toast";
import { addCart } from "../Store/reducers/cartSlice";

const useCart = () => {
  const [cartList, setCartList] = useState([]);
  const [carts, setCarts] = useState([]);
  
  const userInfo = useSelector((store) => store?.auth?.userInfo);
  const storeCarts = useSelector((store) => store?.cart?.carts);
  
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [cartLength, setCartLength] = useState(0);
  const [outOfStock, setOutOfStock] = useState(false);

  const [cartLoading, setCartLoading] = useState(true);

  const dispatch = useDispatch();
  const [quntity, setQuantity] = useState([]);

  const [cartUpdate, setCartUpdate] = useState(false);

  const fetchCartList = async () => {
    try {

      setCartLoading(true);
      const res = await CartServices.getCardById(userInfo._id);
      dispatch(addCart(res.cartData));
      setCartLoading(false);

    } catch (error) {
      console.error("Failed to fetch cart list:", error);
    }
  };

  const handleCartUpdate = () => setCartUpdate(!cartUpdate);

  // useEffect(() => {
  //   fetchCartList();
  // }, [cartUpdate]);

  useEffect(() => {
    
    setCartLoading(true);
    setCartList(storeCarts);
    setCartLoading(false);

  }, [storeCarts]);

  useEffect(() => {
    if (!cartList && !cartList.length) {
      return;
    }

    const filterCartList = {};

    cartList?.forEach(({ cart, _id }) => {
      // console.log(cart);

      if (!filterCartList[cart.productSlug]) {
        filterCartList[cart.productSlug] = {
          ...cart,
          _id,
          eachCart: [{ ...cart, _id }],
          totalQuantity: parseInt(cart?.quantity),
          totalWeight: parseFloat((cart.weight || 0) * cart.quantity),
          totalPrice:
            (cart.offerPrice > 0 ? cart.offerPrice : cart.sellPrice) *
            cart.quantity,
          outOfStockCart: cart?.quantity > cart?.stock ? true : false,
        };
      } else {
        const existingCart = filterCartList[cart.productSlug];
        const newQuantity = parseInt(
          existingCart.totalQuantity + cart.quantity
        );
        const newTotalWeight = parseFloat(
          existingCart.totalWeight + cart.quantity * (cart.weight || 1)
        );
        const newTotalPrice =
          existingCart.totalPrice +
          (cart.offerPrice > 0 ? cart.offerPrice : cart.sellPrice) *
            cart.quantity;
        filterCartList[cart.productSlug] = {
          ...existingCart,
          totalQuantity: newQuantity,
          totalWeight: newTotalWeight.toFixed(2),
          totalPrice: newTotalPrice,
          outOfStockCart:
            cart?.quantity > cart?.stock ? true : existingCart?.outOfStockCart,
        };

        filterCartList[cart.productSlug].eachCart.push({ ...cart, _id });
        // filterCartList[cart.productSlug]
      }
    });

    let checkOutOfStock = cartList?.some(
      ({ cart }) => cart?.quantity > cart?.stock
    );

    if (checkOutOfStock) {
      setOutOfStock(true);
    } else {
      setOutOfStock(false);
    }

    setCarts(Object.values(filterCartList)?.map((val) => ({ cart: val })));
    setCartLength(
      Object.values(filterCartList)?.map((val) => ({ cart: val })).length
    );

    if (cartList && cartList.length > 0) {
      const cartListPrice = cartList.map((item) =>
        Number(item.cart.offerPrice || 0) === 0
          ? Number(item.cart.sellPrice) * Number(item.cart.quantity)
          : Number(item.cart.offerPrice || 0) * Number(item.cart.quantity)
      );

      const total = cartListPrice.reduce(
        (acc, currentValue) => acc + currentValue,
        0
      );

      const weight = cartList.reduce((total, item) => {
        return total + Number(item.cart.weight) * Number(item.cart.quantity);
      }, 0);

      setTotalPrice(total);
      setTotalWeight(weight);
    } else {
      setTotalWeight(0);
      setTotalPrice(0);
    }
  }, [cartList]);

  // console.log(carts);

  const handleSetQunrity = async (quantity, id) => {
    try {
      if (quantity <= 0) {
        notifyError("Quantity Cannnot be less than Zero !!");
        return;
      }

      await CartServices.setCartQuantity(id, {
        quantity: parseInt(quantity),
      });

      fetchCartList();
      dispatch(setNotifyMessage("Quntity Updated Successfully !!"));

    } catch (error) {
      console.log(error);
    }
  };

  const handleDecrement = async (id, index) => {
    try {
      await CartServices.decreementVarintsQuntity(id);
      fetchCartList();

      dispatch(setNotifyMessage("Quntity Updated Successfully !!"));
    } catch (error) {
      console.log(error);
    }
  };

  const handleIncrement = async (id, index) => {
    try {
      await CartServices.increementVariantsQuntity(id);
      fetchCartList();

      dispatch(setNotifyMessage("Quntity Updated Successfully !!"));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCartItem = async (getId) => {
    try {
      const res = await CartServices.deleteCartItem(getId);
      if (res.ok) {
        fetchCartList();
        dispatch(setNotifyMessage(res.message));
      } else {
        dispatch(setNotifyMessage(res.message));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteAllItems = async (items) => {
    const ids = items?.map((item) => item._id);

    try {
      const res = await CartServices.deleteManyCartItems({ ids });
      fetchCartList();
      dispatch(setNotifyMessage(res.message));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    carts,
    cartList,
    totalPrice,
    totalWeight,
    cartLength,
    outOfStock,
    handleCartUpdate,
    handleIncrement,
    handleDecrement,
    handleSetQunrity,
    handleDeleteCartItem,
    deleteAllItems,
    quntity,
    cartLoading,
    fetchCartList
  };
};

export default useCart;
