import React from "react";
import { TbNetworkOff } from "react-icons/tb";
const UserOffline = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white opacity-80 backdrop-blur-sm p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 rounded-lg shadow-lg offline-message">
        {/* <TbNetworkOff style={{width:"100vh"}}/> */}
        <span>Connection Lost !!!</span>
        <p> Please check your internet connection.</p>
      </div>
    </div>
  );
};
export default UserOffline;
