import { useEffect, useState } from "react";
import SearchContext from "./SearchContext";
import useProductDetails from "../../hooks/useProductDetails";

const SearchState = (props) => {
  const [searchSideBar, setSearchSideBar] = useState(false);
  const [cartBar, setCartBar] = useState(false);
  const { cartList } = useProductDetails();
  function HandleSearch() {
    setSearchSideBar(!searchSideBar);
  }
  function HandleCart() {
    setCartBar(!cartBar);
  }
  // useEffect(() => {
  //   if (cartList.length > 0) {
  //     setCartBar(!cartBar);
  //   }
  // }, [cartList]);
  return (
    <SearchContext.Provider
      value={{ searchSideBar, HandleSearch, HandleCart, cartBar }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchState;
