import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import ProductServices from "../services/productServices";
import NotifyAlert, { notifyError, notifySuccess } from "../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import CartServices from "../services/cartServices";
import CustomerService from "../services/CustomerServices";
import useAsync from "../utils/useAsync/useAsync";
import CatgoryServices from "../services/categoryServices";
import { useNavigate } from "react-router-dom";
import { setNotifyMessage } from "../Store/reducers/notifySlice";
import useCustomer from "./useCustomer";
import { addCart } from "../Store/reducers/cartSlice";

const useProductDetails = (id) => {
  const [stockFilter, setStockFilter] = useState();
  const [priceFilter, setPriceFilter] = useState();
  const [titleFilter, setTitleFilter] = useState();
  const [productById, setProductById] = useState({});
  const [attribute, setAttribute] = useState([]);
  const [variants, setVariants] = useState([]);
  const [moreVariants, setMoreVariants] = useState([]);
  const [variantTitle, setvariantTitle] = useState([]);
  const [variantTitleMore, setvariantTitleMore] = useState([]);
  const [variantsValue, setVariantsValue] = useState([]);
  const [defultValue, setDefaultValue] = useState([]);
  const [moreVariantsValue, setMoreVariantsValue] = useState([]);
  const [categories, setCatgorise] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);
  const [selectedMoreVariants, setSelectedMoreVariants] = useState([]);
  const [productVariantVal, setProductVariantVal] = useState([]);
  const [productVarintsCartData, setProductVarintCartData] = useState([]);
  const [productVariantValMore, setProductVariantValMore] = useState([]);

  const [cartMoreData, setCartMoreData] = useState([]);
  const [chainCategory, setChainCategory] = useState(false);

  const [childID, setChildID] = useState("");
  const [checkSize, setCheckSize] = useState({});
  const [checkOpicityMore, setCheckOpicityMore] = useState(false);
  const [parentIndex, setParentIndex] = useState([-1]);
  const [selectParentColor, setParentColor] = useState("");
  const [selectChildColor, setChildColor] = useState("");
  const [nextActiveVarient, setNextActiveVarient] = useState();

  const [selectVarient, setSelectVarient] = useState(false);
  const [selectVarColor, setSelectVarColor] = useState(false);
  const [selectVarStone, setSelectVarStone] = useState(false);
  const [selectVarSize, setSelectVarSize] = useState(false);

  const [sellPrice, setSellPrice] = useState(0);
  const [weight, setWeight] = useState(0);
  const [sellPriceMore, setSellPriceMore] = useState(0);
  const [WeightMore, setWeightMore] = useState(0);
  const [stockQuntity, setStockQuntity] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [quantityMore, setQuantityMore] = useState([]);
  const [totalPrice, setTotalPrice] = useState(Number);
  const [totalWeight, setTotalWeight] = useState(Number);
  const [sizeData, setSizeData] = useState([]);
  const [cartImage, setCartImage] = useState({});
  const [wishlist, setWishlist] = useState(false);
  const [categoryId, setCategoryId] = useState("");
  const [loadingApi, setLoadinApi] = useState(false);
  const userInfo = useSelector((store) => store?.auth?.userInfo);
  const sliderRef = React.useRef(null);
  const [cartList, setCartList] = useState(0);
  const [thirdLyerCategory, setThirdLyerCategory] = useState([]);
  const dispatch = useDispatch();

  const { data, error, loading } = useAsync(
    async () => await CatgoryServices.getShowingCategory()
  );
  const fetchCartList = async () => {
    try {
      const res = await CartServices.getCardById(userInfo._id);
      setCartList(res?.cartData);
      dispatch(addCart(res?.cartData));
    } catch (error) {
      console.error("Failed to fetch cart list:", error);
    }
  };

  useEffect(() => {
    fetchCartList();
  }, []);

  useEffect(() => {
    try {
      (async () => {
        if (id) {
          setLoadinApi(true);
          const product = await ProductServices.getProductById(id);
          setProductById(product);
          if (product) {
            setVariants(product.variants || []);
            setMoreVariants(product.moreVariants || []);
            setCategoryId(product.category[0]._id || "");
            let arr = [];
            product.variants.forEach((variant) => {
              Object.keys(variant).forEach((key) => {
                const value = variant[key];
                arr.push(value);
              });
            });
            const filteredArr = [...new Set(arr)];
            setVariantsValue(filteredArr);

            const arrMore = [];
            product.moreVariants.forEach((variant) => {
              Object.keys(variant).forEach((key) => {
                const value = variant[key];
                arrMore.push(value);
              });
            });
            const filteredArrMore = [...new Set(arrMore)];
            setMoreVariantsValue(filteredArrMore);
            setChainCategory(
              product?.category[0]?.slug ===
                "metals_brass-silver-plating_chains" ||
                product?.category[0]?.slug === "metals_silver-92.5_chains"
            );
            setLoadinApi(false);
          }
        }
        return;
      })();
    } catch (err) {
      dispatch(setNotifyMessage(err.message));
    }
  }, [stockFilter, priceFilter, titleFilter, id]);
  useEffect(() => {
    try {
      (async () => {
        const attribute = await ProductServices.getShowingAttributes();
        const res = Object?.keys(Object.assign({}, ...variants));
        const resValue = Object?.values(Object.assign({}, variants[0]));
        const res2 = Object?.keys(Object.assign({}, ...moreVariants));
        const varTitle = attribute?.filter((att) => res.includes(att._id));
        const varTitleMore = attribute?.filter((att) => res2.includes(att._id));
        setvariantTitleMore(varTitleMore);
        setvariantTitle(varTitle);
        setAttribute(attribute);
        setDefaultValue(resValue);
      })();
    } catch (err) {
      console.log(err);
    }
  }, [variants, moreVariants]);

  const handleStockFilter = (e) => {
    setStockFilter(e.target.value);
  };
  const handlePriceFilter = (e) => {
    setPriceFilter(e.target.value);
  };
  const handleTitleFilter = (e) => {
    setTitleFilter(e.target.value);
  };

  const [selectedVariant, setSelectedVariant] = useState([]);
  const addToCartScrollToTopHandler = () => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }, 1000);

    return true;
  };
  const handleVariantBtn = (
    pId,
    cId,
    pIndex,
    parentColorIndex,
    childColorIndex
  ) => {
    if (!parentIndex.includes(pIndex - 1) && pIndex > 0) {
      // notifyError("Please Select Above variants first");
      // dispatch(setNotifyMessage("Please Select Above variants Colour"));
      return false;
    }

    if (!selectedVariants) {
      // notifyError("Selected variants are null or undefined");
      dispatch(setNotifyMessage("Selected variants are null or undefined"));
      return false;
    }

    setParentIndex((prev) => {
      const updatedIndices = prev.includes(pIndex) ? prev : [...prev, pIndex];
      return updatedIndices;
    });

    if (chainCategory) {
      setQuantity(10);
    } else {
      setQuantity(1);
    }

    setWeight(0);
    setProductVarintCartData([]);
    setNextActiveVarient(pIndex + 1);

    setSelectedVariant((prev) => {
      const newArr = [...prev];
      newArr[pIndex] = parentColorIndex;
      return newArr;
    });

    setParentColor(parentColorIndex);
    setChildColor(childColorIndex);

    const newVariant = [...selectedVariants];
    newVariant[pIndex] = { pId, cId };
    const filteredData = newVariant.filter(
      (item) => item !== null || item !== undefined
    );
    setSelectedVariants(filteredData);

    return true;
  };

  useEffect(() => {
    if (
      (productById && productById.isCombination) ||
      productById.moreCombination
    ) {
      const checkAtt = variantTitle?.map((variant) =>
        variant?.variants?.map((childV) => variantsValue.includes(childV._id))
      );
      if (selectedVariants.length === checkAtt.length) {
        const filteredVariants = variants.filter((variant) =>
          selectedVariants.every((obj) => variant[obj?.pId] === obj?.cId)
        );
        const checkOffer =
          Number(filteredVariants[0]?.offerPrice) === 0 ? true : false;

        const newSellPrice = !checkOffer
          ? Number(filteredVariants[0]?.offerPrice) * quantity
          : Number(filteredVariants[0]?.sellPrice) * quantity;
        const newWeight = filteredVariants[0]?.weight * quantity;
        setSellPrice(newSellPrice);
        setWeight(newWeight);
        setProductVariantVal(filteredVariants);

        if (chainCategory && quantity === 10) {
          setProductVarintCartData((prevState) => {
            const itemIndex = prevState?.findIndex(
              (item) => item?.productId === filteredVariants[0]?.productId
            );

            if (itemIndex !== -1) {
              const newState = [...prevState];
              newState[itemIndex] = {
                ...prevState[itemIndex],
                quantity: prevState[itemIndex].quantity,
              };
              return newState;
            } else {
              return [...prevState, { ...filteredVariants[0], quantity: 10 }];
            }
          });
        }

        if (filteredVariants.length === 1 && quantity === 1) {
          setProductVarintCartData((prevState) => {
            const itemIndex = prevState?.findIndex(
              (item) => item?.productId === filteredVariants[0]?.productId
            );

            if (itemIndex !== -1) {
              const newState = [...prevState];
              newState[itemIndex] = {
                ...prevState[itemIndex],
                quantity: prevState[itemIndex].quantity,
              };
              return newState;
            } else {
              return [...prevState, { ...filteredVariants[0], quantity: 1 }];
            }
          });
        }
      } else {
        setProductVariantVal([]);
        setSellPrice(0);
        setWeight(0);
      }
    }
  }, [
    selectedVariants,
    variants,
    variantTitle,
    variantsValue,
    quantity,
    productById,
  ]);

  const handleWeightVariants = (val) => {};

  const handleBarcodeColor = (val) => {};

  useEffect(() => {
    if (selectedMoreVariants.length >= 0) {
      const filteredVariants = moreVariants.filter((variant) =>
        selectedMoreVariants.every((obj) => variant[obj?.pId] === obj?.cId)
      );
      // console.log('sj', filteredVariants);
      setProductVariantValMore(filteredVariants);
    } else {
      setProductVariantValMore([]);
      setSellPriceMore();
    }
  }, [variantTitle, selectedMoreVariants, moreVariants]);

  useEffect(() => {
    if (!variantTitleMore || variantTitleMore.length === 0) {
      return;
    }
    const lastVariant = variantTitleMore[variantTitleMore.length - 1];
    let newArr;
    newArr = lastVariant?.variants;
    if (newArr) {
      setQuantityMore(Array(newArr[0].length).fill(0));
    }
  }, [variantTitle, moreVariantsValue]);

  const handleSelectVariant = (cId, pId, index) => {
    if (!selectedMoreVariants) {
      // notifyError("Selected variants is null or undefined");
      dispatch(setNotifyMessage("Selected variants is null or undefined"));

      return;
    }
    // setCartMoreData([]);
    // setWeightMore(0);
    // setSellPriceMore((prev)=>{

    // });
    // setQuantityMore([]);
    const newVariant = [...selectedMoreVariants];
    newVariant[index] = { pId, cId };
    const filteredData = newVariant.filter(
      (item) => item !== null || item !== undefined
    );
    // console.log('filterData', filteredData);
    setSelectedMoreVariants(filteredData);
  };

  useEffect(() => {
    if (selectedMoreVariants.length === variantTitleMore.length - 1) {
      setCheckOpicityMore(true);
    } else {
      setCheckOpicityMore(false);
    }
  }, [selectedMoreVariants, variantTitleMore]);

  let incNum = () => {
    if (chainCategory) {
      setProductVarintCartData((prevState) => {
        const itemIndex = prevState?.findIndex(
          (item) => item?.productId === productVariantVal[0]?.productId
        );

        if (itemIndex !== -1) {
          const newState = [...prevState];
          newState[itemIndex] = {
            ...prevState[itemIndex],
            quantity:
              prevState[itemIndex].quantity === 1
                ? 10
                : prevState[itemIndex].quantity + 10,
          };
          return newState;
        } else {
          return [...prevState, { ...productVariantVal[0], quantity: 10 }];
        }
      });

      if (quantity < 50000) {
        setQuantity((prev) =>
          prev === 1 ? Number(prev) + 9 : Number(prev) + 10
        );
      }
      return;
    } else {
      setProductVarintCartData((prevState) => {
        const itemIndex = prevState?.findIndex(
          (item) => item?.productId === productVariantVal[0]?.productId
        );

        if (itemIndex !== -1) {
          const newState = [...prevState];
          newState[itemIndex] = {
            ...prevState[itemIndex],
            quantity: prevState[itemIndex].quantity + 1,
          };
          return newState;
        } else {
          return [...prevState, { ...productVariantVal[0], quantity: 1 }];
        }
      });
    }

    if (quantity < 50000) {
      setQuantity(Number(quantity) + 1);
    }
  };
  const handleSetQuntityVarints = (val) => {
    console.log(val);

    if (val !== "" && val <= 0) {
      return;
    }

    setProductVarintCartData((prevState) => {
      const itemIndex = prevState?.findIndex(
        (item) => item?.productId === productVariantVal[0]?.productId
      );

      if (itemIndex !== -1) {
        const newState = [...prevState];
        newState[itemIndex] = {
          ...prevState[itemIndex],
          quantity: Number(val),
        };
        return newState;
      } else {
        return [
          ...prevState,
          { ...productVariantVal[0], quantity: Number(val) },
        ];
      }
    });
    setQuantity(Number(val));
  };

  // console.log(productVarintsCartData, quantity, chainCategory);

  let decNum = () => {
    // console.log(productVarintsCartData, quantity);

    if (chainCategory) {
      if (quantity <= 10) {
        return;
      }

      setProductVarintCartData((prevState) => {
        const itemIndex = prevState?.findIndex(
          (item) => item?.productId === productVariantVal[0]?.productId
        );

        if (itemIndex !== -1) {
          const newState = [...prevState];
          newState[itemIndex] = {
            ...prevState[itemIndex],
            quantity: prevState[itemIndex].quantity - 10,
          };
          return newState;
        } else {
          return [...prevState, { ...productVariantVal[0], quantity: 10 }];
        }
      });

      if (quantity > 10) {
        setQuantity(Number(quantity) - 10);
      }

      return;
    }

    // console.log(productVarintsCartData, quantity, '---');
    if (quantity <= 1) {
      return;
    }

    // console.log(productVarintsCartData, quantity);

    setProductVarintCartData((prevState) => {
      const itemIndex = prevState?.findIndex(
        (item) => item?.productId === productVariantVal[0]?.productId
      );

      if (itemIndex !== -1) {
        const newState = [...prevState];
        newState[itemIndex] = {
          ...prevState[itemIndex],
          quantity: prevState[itemIndex].quantity - 1,
        };

        return newState;
      } else {
        return [...prevState, { ...productVariantVal[0], quantity: 1 }];
      }
    });
    if (quantity > 1) {
      setQuantity(Number(quantity) - 1);
    }
  };

  const handleIncrement = (index, cartData) => {
    setCartMoreData((prevState) => {
      const itemIndex = prevState?.findIndex(
        (item) => item?.productId === cartData?.productId
      );

      if (itemIndex !== -1) {
        const newState = [...prevState];
        newState[itemIndex] = {
          ...prevState[itemIndex],
          quantity: Number(prevState[itemIndex].quantity) + 1,
        };
        if (newState[itemIndex].quantity > cartData?.quantity) {
          dispatch(
            setNotifyMessage(
              "This Product is Variant out of stock. If you proceed, the entire order will ship within 45 days."
            )
          );
        }
        // console.log(cartData, cartMoreData, newState[itemIndex]);
        return newState;
      } else {
        if (cartData?.stock === 0) {
          dispatch(
            setNotifyMessage(
              "This Product is Variant out of stock. If you proceed, the entire order will ship within 45 days."
            )
          );
        }
        return [...prevState, { ...cartData, quantity: 1 }];
      }
    });

    setQuantityMore((prevQuantityMore) => {
      const newQuantity = [...prevQuantityMore];
      newQuantity[index] = (newQuantity[index] ?? 0) + 1;
      return newQuantity.map((item) => (item === undefined ? 0 : item));
    });
  };
  const handleDecrement = (index, cartData) => {
    if (quantityMore[index] > 0) {
      setCartMoreData((prevState) => {
        const emptyItemIndex = prevState?.findIndex(
          (item) => Number(item.quantity) === 0
        );
        if (emptyItemIndex !== -1) {
          return [];
        }
        const itemIndex = prevState.findIndex(
          (item) => item?.productId === cartData?.productId
        );

        if (itemIndex !== -1) {
          const newState = [...prevState];
          newState[itemIndex] = {
            ...prevState[itemIndex],
            quantity: Number(prevState[itemIndex].quantity) - 1,
          };

          // if (newState[itemIndex].quantity > cartData?.quantity) {
          //   dispatch(
          //     setNotifyMessage(
          //       "This Product is Variant out of stock. If you proceed, the entire order will ship within 45 days."
          //     )
          //   );
          // }

          if (newState[itemIndex].quantity <= 0) {
            newState.splice(itemIndex, 1);
          }

          return newState;
        } else {
          return [...prevState, { ...cartData, quantity: 1 }];
        }
      });
    }

    if (quantityMore[index] > 0) {
      setQuantityMore((prevQuantityMore) => {
        const newQuantity = [...prevQuantityMore];
        newQuantity[index] = prevQuantityMore[index] - 1;
        return newQuantity;
      });
    }
  };

  const handleSetQuantity = (val, index, cartData) => {
    setCartMoreData((prevState) => {
      const itemIndex = prevState?.findIndex(
        (item) => item?.productId === cartData?.productId
      );
      if (itemIndex !== -1) {
        const updatedQuantity = Number(val);
        if (updatedQuantity <= 0) {
          const newState = [...prevState];
          newState.splice(itemIndex, 1);
          return newState;
        } else {
          const newState = [...prevState];
          newState[itemIndex] = {
            ...prevState[itemIndex],
            quantity: updatedQuantity,
          };

          if (newState[itemIndex].quantity > cartData?.quantity) {
            dispatch(
              setNotifyMessage(
                "This Product is Variant out of stock. If you proceed, the entire order will ship within 45 days."
              )
            );
          }
          return newState;
        }
      } else {
        if (Number(val) > 0) {
          return [...prevState, { ...cartData, quantity: Number(val) }];
        }
        return prevState;
      }
    });
    setQuantityMore((prevQuantityMore) => {
      const newQuantity = [...prevQuantityMore];
      newQuantity[index] = Number(val);
      return newQuantity.map((item) => (item === undefined ? 0 : item));
    });
  };

  const getVariantName = (pId, cId, variantTitle) => {
    let variantName = null;
    variantTitle.forEach((variant) => {
      if (variant._id === pId) {
        variant.variants.forEach((childV) => {
          if (childV._id === cId && moreVariantsValue.includes(childV._id)) {
            variantName = childV;
          }
        });
      }
    });
    return variantName;
  };
  const handleProductVariantsFilterd = (pId, cId) => {
    if (!productVariantValMore) {
      dispatch(setNotifyMessage("Selected variants is null or undefined"));
      return;
    }

    let data = {};
    const variantName = getVariantName(pId, cId, variantTitleMore);
    if (!variantName) {
      return { variantName: {}, sizes: Object.values(data) };
    }
    productVariantValMore.forEach((v) => {
      if (v[pId] === cId) {
        if (!data[cId]) {
          data[cId] = [];
        }
        const sizes = getVariantName(
          Object.keys(v)[selectedMoreVariants.length],
          Object.values(v)[selectedMoreVariants.length],
          variantTitleMore
        );
        setCheckSize(sizes);
        if (sizes && Object.keys(sizes).length > 0) {
          data[cId].push({ v, sizes });
        }
      }
    });
    return { variantName, sizes: Object.values(data) };
  };
  const handleSizeSelect = (selectedOption) => {
    const { result, index } = JSON.parse(selectedOption);
    const { sellPrice, weight } = result;
    setSizeData((prevState) => {
      const itemIndex = prevState.findIndex(
        (item) => item.productId === result.productId
      );
      if (itemIndex !== -1) {
        return prevState;
      } else {
        return [...prevState, { ...result, quantity: 0 }];
      }
    });

    setSellPriceMore((prevSellPriceMore) => {
      return { ...prevSellPriceMore, [index]: Number(sellPrice) };
    });

    setWeightMore((prevWeightMore) => {
      return { ...prevWeightMore, [index]: weight };
    });
  };
  useEffect(() => {
    if (cartMoreData && cartMoreData.length > 0) {
      const totalPrice = cartMoreData.reduce((acc, curItem) => {
        const priceToUse =
          Number(curItem?.offerPrice) !== 0
            ? Number(curItem?.offerPrice)
            : Number(curItem?.sellPrice);
        return acc + (Number(curItem?.quantity) || 0) * (priceToUse || 0);
      }, 0);
      setTotalPrice(totalPrice);

      const totalWeight = cartMoreData.reduce(
        (acc, curItem) =>
          acc + Number(curItem?.quantity ?? 0) * Number(curItem?.weight ?? 0),
        0
      );
      setTotalWeight(totalWeight);
    } else {
      const indexes = Object.keys(sellPriceMore || {});
      const indexesWeight = Object.keys(WeightMore || {});

      const totalPrice = indexes.reduce(
        (acc, idx) =>
          acc + (quantityMore[idx] ?? 0) * (sellPriceMore[idx] ?? 0),
        0
      );
      setTotalPrice(totalPrice);

      const totalWeight = indexesWeight.reduce(
        (acc, idx) => acc + (quantityMore[idx] ?? 0) * (WeightMore[idx] ?? 0),
        0
      );
      setTotalWeight(totalWeight);
    }
  }, [sellPriceMore, quantityMore, WeightMore, cartMoreData]);

  const HandleAddToCartMore = async () => {
    try {
      if (!cartMoreData.length > 0) {
        // notifyError("Please Select Variant First");
        dispatch(setNotifyMessage("Please Select Variant First"));
        return;
      }

      let totalQuantity = quantityMore?.reduce((sum, acc) => sum + acc, 0);

      if (totalQuantity < 5) {
        dispatch(
          setNotifyMessage("Minimum 5 Pieces are requierd to Add to Cart")
        );
        return;
      }

      const checkCartItm = cartList?.some(
        (cartItem) => cartItem?.cart.productId === cartMoreData[0]?.productId
      );

      // console.log(checkCartItm, cartList, cartMoreData);
      // return;

      if (!checkCartItm) {
        const dataDynamic = {
          totalPrice: parseInt(totalPrice),
          totalWeight: parseInt(totalWeight),
          catgorise: productById?.categories,
          prentCatgorise:
            productById?.categories[productById?.categories?.length - 1]
              ?.name || "",
          childCatgory:
            thirdLyerCategory.length > 0 && thirdLyerCategory[0]?.name,
          subChildCategory:
            productById?.categories[productById?.categories?.length - 3]
              ?.name || "",
          productSlug: id,
          status: "Pending",
          // image: cartImage,
          image: productById?.imageUrl_thum[0],
          user: userInfo._id,
          title: productById.title,
          isCombination: productById.isCombination,
          moreCombination: productById.moreCombination,
          mixedVariant: false,
          directly: true,
        };
        const cartActualData = cartMoreData?.map((itm) => {
          return { ...itm, ...dataDynamic };
        });

        cartActualData?.map(async (itm) => {
          await CartServices.addToCart(itm);
        });
        dispatch(setNotifyMessage("Item Added to Cart !!"));

        fetchCartList();
        setCartMoreData([]);
        setWeightMore(0);
        setSellPriceMore([]);
        setQuantityMore([]);
      } else {
        const updateDtat = cartMoreData?.map((itm) => {
          return { id: itm.productId, quantity: itm.quantity };
        });
        await CartServices.updateCartQuntityMoreVarints(updateDtat);
        fetchCartList();
        setCartMoreData([]);
        setWeightMore(0);
        setSellPriceMore([]);
        setQuantityMore([]);
        dispatch(setNotifyMessage("Item Added to Cart !!"));
      }
    } catch (err) {
      dispatch(setNotifyMessage(err?.response?.data?.message || err.message));
    }
  };
  const HandleAddToCart = async () => {
    try {
      if (!productVarintsCartData.length > 0) {
        dispatch(setNotifyMessage("Please Select Variant First"));
        return;
      }
      const checkCartItm = cartList?.some(
        (cartItem) =>
          cartItem?.cart.productId === productVarintsCartData[0]?.productId
      );

      if (quantity > productVarintsCartData[0].stock) {
        dispatch(
          setNotifyMessage(
            "Item Added To Cart (This Product is out of stock. If you proceed, the entire order will ship within 45 days.)"
          )
        );
        // return;
      } else {
        dispatch(setNotifyMessage("Item Added To Cart !!"));
      }

      // console.log(quantity, productVarintsCartData[0].stock);
      // return;

      if (!checkCartItm) {
        const dataDynamic = {
          totalPrice: parseInt(
            productVarintsCartData[0]?.offerPrice != 0
              ? productVarintsCartData[0]?.offerPrice * quantity
              : productVarintsCartData[0].sellPrice * quantity
          ),
          totalWeight: parseFloat(productVarintsCartData[0].weight * quantity),
          catgorise: productById?.categories,
          prentCatgorise:
            productById?.categories[productById?.categories?.length - 1]
              ?.name || "",
          childCatgory:
            thirdLyerCategory.length > 0 && thirdLyerCategory[0]?.name,
          subChildCategory:
            productById?.categories[productById?.categories?.length - 3]
              ?.name || "",
          productSlug: id,
          status: "Pending",
          // image: cartImage,
          image: productById?.imageUrl_thum[0],
          user: userInfo._id,
          title: productById.title,
          isCombination: productById.isCombination,
          moreCombination: productById.moreCombination,
          mixedVariant: true,
          directly: false,
          stock: productVarintsCartData[0].stock,
        };

        // console.log(dataDynamic, productVarintsCartData);
        // return;
        const cartMappedData = productVarintsCartData?.map((itm) => {
          return { ...itm, ...dataDynamic };
        });
        await CartServices.addToCart(cartMappedData[0]);
        fetchCartList();
        setParentIndex([-1]);
        setSelectedVariant([]);
        setNextActiveVarient();

        // notifySuccess("Item Added to Cart !!");
        // dispatch(setNotifyMessage("Item Added to Cart !!"));
      } else {
        await CartServices.updateCartQuntityVarints(
          productVarintsCartData[0]?.productId,
          {
            quantity: productVarintsCartData[0]?.quantity,
          }
        );

        fetchCartList();
        setParentIndex([-1]);
        setSelectedVariant([]);
        setNextActiveVarient();

        // notifySuccess("Quntity added to cart!");
        // dispatch(setNotifyMessage("Quantity added to cart!"));
      }
    } catch (err) {
      // notifyError(err?.response?.data?.message || err.message);
      dispatch(setNotifyMessage(err?.response?.data?.message || err.message));
    }
  };

  useEffect(() => {
    if (
      productById &&
      !productById?.isCombination &&
      !productById?.moreCombination
    ) {
      setSellPrice(
        productById?.prices?.offerPrice > 0
          ? productById?.prices?.offerPrice * quantity
          : productById?.prices?.sellPrice * quantity
      );
    }
  }, [quantity, productById]);

  const HandleAddToCartSingal = async () => {
    try {
      const checkCartItm = cartList?.some(
        (cartItem) => cartItem?.cart.productId === productById?.productId
      );

      if (quantity > productById.stock) {
        dispatch(
          setNotifyMessage(
            "Item Added To Cart (This Product is out of stock. If you proceed, the entire order will ship within 45 days.)"
          )
        );
        // return;
      } else {
        dispatch(setNotifyMessage("Item Added To Cart !!"));
      }

      if (!checkCartItm) {
        const dataDynamic = {
          // totalPrice: parseInt(productById?.prices?.offerPrice!==0 ? (productById?.prices?.offerPrice * quantity) : (productById?.prices?.sellPrice * quantity)),
          // totalWeight: parseFloat(productById?.weight * quantity),
          // weight: parseFloat(totalWeight),
          weight: parseFloat(productById?.weight),
          sellPrice: parseInt(productById?.prices?.sellPrice),
          costPrice: parseInt(productById?.prices?.costPrice),
          offerPrice: parseInt(productById?.prices?.offerPrice),
          catgorise: productById?.categories,
          prentCatgorise:
            productById?.categories[productById?.categories?.length - 1]
              ?.name || "",
          childCatgory:
            thirdLyerCategory.length > 0 && thirdLyerCategory[0]?.name,
          subChildCategory:
            productById?.categories[productById?.categories?.length - 3]
              ?.name || "",
          quantity: quantity,
          productId: productById?.productId,
          productSlug: id,
          status: "Pending",
          // image: cartImage,
          image: productById?.imageUrl_thum[0],
          user: userInfo._id,
          title: productById.title,
          isCombination: productById.isCombination,
          moreCombination: productById.moreCombination,
          mixedVariant: false,
          directly: false,
          stock: productById.stock,
        };
        const res = await CartServices.addToCart(dataDynamic);
        // notifySuccess("Item Added to Cart !!");
        // dispatch(setNotifyMessage("Item Added to Cart !!"));
        fetchCartList();

        setParentIndex([-1]);
        setSelectedVariant([]);
        setNextActiveVarient();
      } else {
        await CartServices.updateCartQuntitySingle(productById?.productId, {
          quantity: quantity,
        });

        fetchCartList();

        setParentIndex([-1]);
        setSelectedVariant([]);
        setNextActiveVarient();

        // notifySuccess("Quntity added to cart!");
        // dispatch(setNotifyMessage("Quantity added to cart!"));
      }
    } catch (err) {
      // notifyError(err?.response?.data?.message || err.message);
      dispatch(setNotifyMessage(err?.response?.data?.message || err.message));
    }
  };

  const handleDeleteCartItem = async (getId) => {
    try {
      const res = await CartServices.deleteCartItem(getId);
      if (res.ok) {
        fetchCartList();
        return dispatch(setNotifyMessage(res.message));
        // notifySuccess(res.message);
      } else {
        return dispatch(setNotifyMessage(res.message));
        // notifyError(res.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddWishlist = async (id) => {
    try {
      const res = await CustomerService.addToWishlist(userInfo?._id, {
        productId: id,
      });
      setWishlist(true);
      // dispatch(setNotifyMessage(res.message));
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveWishlist = async (id) => {
    try {
      const res = await CustomerService.removeFromWishlist(userInfo?._id, {
        productId: id,
      });
      setWishlist(false);
      // dispatch(setNotifyMessage(res.message));
    } catch (error) {
      console.log(error);
    }
  };

  const CheckWishlist = async (id) => {
    try {
      const res = await CustomerService.CheckWishlist(userInfo?._id, {
        productId: id,
      });
      if (res?.added) {
        setWishlist(true);
      } else {
        setWishlist(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!productById?._id) {
      return;
    }
    CheckWishlist(productById?._id);
  }, [productById]);

  useEffect(() => {
    const categorise = data[0]?.children
      ?.map((el) => {
        const category = productById?.category?.find(
          (ecl) => el._id === ecl._id
        );
        return category ? category : null;
      })
      .filter(Boolean);
    setCatgorise(categorise);
  }, [data, productById]);

  useEffect(() => {
    const extractThirdChildren = (categories, layer = 0) => {
      let thirdChildren = [];
      categories.forEach((category) => {
        if (layer === 2) {
          thirdChildren.push(category);
        } else if (category.children && category.children.length > 0) {
          const grandchildren = extractThirdChildren(
            category.children,
            layer + 1
          );
          thirdChildren = [...thirdChildren, ...grandchildren];
        }
      });
      return thirdChildren;
    };
    const thirdLayerChildren = extractThirdChildren(data);
    const commonObjects = thirdLayerChildren.filter((obj1) =>
      productById?.categories?.some((obj2) => obj2._id === obj1._id)
    );
    setThirdLyerCategory(commonObjects);
  }, [data, productById]);
  // console.log(productById)
  return {
    loadingApi,
    sizeData,
    productById,
    variantTitle,
    variants,
    checkOpicityMore,
    selectedMoreVariants,
    decNum,
    cartMoreData,
    checkSize,
    attribute,
    incNum,
    totalPrice,
    quantityMore,
    handleDecrement,
    handleIncrement,
    quantity,
    productVariantValMore,
    sellPrice,
    productVariantVal,
    variantsValue,
    categoryId,
    childID,
    // selectedAttribute,
    moreVariantsValue,
    handleStockFilter,
    handleVariantBtn,
    handlePriceFilter,
    handleTitleFilter,
    handleWeightVariants,
    handleBarcodeColor,
    handleSelectVariant,
    handleProductVariantsFilterd,
    handleSizeSelect,
    sellPriceMore,
    WeightMore,
    HandleAddToCartMore,
    HandleAddToCart,
    HandleAddToCartSingal,
    sliderRef,
    setCartImage,
    cartList,
    handleDeleteCartItem,
    selectVarColor,
    selectVarSize,
    selectVarStone,
    selectVarient,
    setSelectVarColor,
    setSelectVarSize,
    setSelectVarStone,
    setSelectVarient,
    handleAddWishlist,
    handleRemoveWishlist,
    wishlist,
    setQuantity,
    setQuantityMore,
    handleSetQuantity,
    variantTitleMore,
    parentIndex,
    selectParentColor,
    selectChildColor,
    selectedVariant,
    nextActiveVarient,
    handleSetQuntityVarints,
    addToCartScrollToTopHandler,
    fetchCartList,
    setCartList,
    defultValue,
    chainCategory,
    productVarintsCartData,
  };
};
export default useProductDetails;
