import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import io from "socket.io-client";
import { BASE_URL_SOCKET } from '../constants/constants';

const useOnlineUser = () => {

  const userInfo = useSelector((store) => store?.auth?.userInfo);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    try {
      setSocket(io( `${BASE_URL_SOCKET}/userIO`, {
        auth: {
          token: userInfo?.token
        }
      }));
    } catch (error) {
      console.log(error)
    }

  }, [userInfo]);

  useEffect(() => {
    return () => {
      socket?.disconnect();
    };
  }, [socket]);

  return {
    socket,
  };
}

export default useOnlineUser;