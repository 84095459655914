import React, { useEffect, useState } from "react";
import ProductServices from "../services/productServices";
import StoreSettingService from "../services/StoreSetiingServices";
import { useDispatch, useSelector } from "react-redux";
import { addStoreSetting } from "../Store/reducers/storeSettings";
import { notifySuccess } from "../utils/toast";
import { setShowingLanguages } from "../Store/reducers/languageSlice";
import LanguageServices from "../services/LanguageServices";
import CurrencyServices from "../services/CurrencyServices";
import {
  setCurrencyData,
  setShowingCurrency,
} from "../Store/reducers/currencySlice";
import CartServices from "../services/cartServices";
import { addCart, clearCarts } from "../Store/reducers/cartSlice";
import CustomerService from "../services/CustomerServices";
import { removeUser } from "../Store/reducers/authSlice";
import Cookies from "js-cookie";

const useStoreData = () => {
  const [netErr, setNetErr] = useState(false);
  const dispatch = useDispatch();

  const userInfo = useSelector((store) => store.auth.userInfo);

  useEffect(() => {
    (async () => {
      try {
        const data = await StoreSettingService.getStoreCustomizationSetting();
        dispatch(addStoreSetting(data));
      } catch (err) {
        console.log(err);
        setNetErr(err.code === "ERR_NETWORK");
      }
    })();
  }, []);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const data = await LanguageServices.getShowingLanguage();
        dispatch(setShowingLanguages(data));
      } catch (error) {
        console.error("Error fetching languages:", error);
      }
    };

    fetchLanguages();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currencyRes = await CurrencyServices.getShowingCurrency();
        dispatch(setShowingCurrency(currencyRes));

        const data = {};

        currencyRes?.map((c) => {
          data[c?.code] = c.exchange_rate;
        });

        dispatch(setCurrencyData(data));
      } catch (error) {
        console.error("Error fetching currencies:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (!userInfo && !userInfo?._id) {
      return;
    }

    const fetchCartList = async () => {
      try {
        
        const res = await CartServices.getCardById(userInfo._id);
        dispatch(addCart(res.cartData));

      } catch (error) {
        console.error("Failed to fetch cart list:", error);
      }
    };

    fetchCartList();
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo && !userInfo?._id) {
      return;
    }

    const fetchCustomer = async () => {
      try {
        
        const res = await CustomerService.checkCustomerVerified(userInfo._id);

        if(!res.verified){
          Cookies.remove("userInfo");
          dispatch(removeUser());
          dispatch(clearCarts());
        }

      } catch (error) {
        console.error("Failed to fetch cart list:", error);
      }
    };

    fetchCustomer();
  }, [userInfo]);

  return {
    netErr,
  };
};

export default useStoreData;
