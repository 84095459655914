// languageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const lang = JSON.parse(Cookies.get("lang") || "{}");

const languageSlice = createSlice({
  name: "language",

  initialState: { value: lang?.lang || "en", name: lang?.name || "ENGLISH", showingLanguages: [] },

  reducers: {
    setLanguage: (state, action) => {
      Cookies.set(
        "lang",
        JSON.stringify({
          name: action.payload.name,
          lang: action.payload.lang
        }), {
          sameSite: "None",
          secure: true,
        }
      );

      state.value = action.payload.lang;
      state.name = action.payload.name;
    },

    setShowingLanguages: (state, action) => {
      state.showingLanguages = action.payload;
    }
  },
});

export const { setLanguage, setShowingLanguages } = languageSlice.actions;
export default languageSlice.reducer;
