import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({

    name: "productSlice",

    initialState: {
        productID: null,
        productActiveKey: null,
    },

    reducers: {

        addActiveProductID: (state, action) => {
            state.productID = action.payload;
        },

        productDetailsKey: (state, action) => {
            state.productActiveKey = action.payload;
        },
    }
});

export const { addActiveProductID, productDetailsKey } = productSlice.actions;
export default productSlice.reducer;