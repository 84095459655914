import requests from './httpService';

const CurrencyServices = {

  getShowingCurrency: async () => {
    return requests.get('/currency/show');
  }

};

export default CurrencyServices;