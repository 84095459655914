import requests from "./httpService";

const NewsletterService = {

  unsubscibeCustomer : async (token) => {
    return requests.delete(`/newsletter/customer/unsubscribe/${token}`);
  },

};
export default NewsletterService;
