import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { privateRoutes, routes } from "./routes/routes";
import Footer from "./components/footer/Footer";
import useStoreData from "./hooks/useStoreData";
import PrivateRoute from "./routes/PrivateRoute";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import { useDispatch, useSelector } from "react-redux";
import useOnlineUser from "./hooks/useOnlineUser";
import UserOffline from "./components/pages/UserOffline";
import AllHeader from "./components/subHeader/AllHeader";
import NotifyAlert from "./utils/toast";
import { changeScrolling } from "./Store/reducers/clientSettingSlice";

const App = () => {
  const { netErr } = useStoreData();
  useOnlineUser();

  const location = useLocation();
  const headerData = useSelector((store) => store?.storeSettings?.header);
  const [online, setOnline] = useState(navigator.onLine);

  const { productActiveKey, productID } = useSelector(
    (store) => store?.activeProductID
  );
  const toggleScrolling = useSelector(
    (store) => store?.clientSettings?.toggleScrolling
  );

  const showAllHeader =
    location.pathname === "/" ||
    location.pathname === `/eshop/detail/${productID}` ||
    location.key === productActiveKey;

  const dispatch = useDispatch();

  useEffect(() => {
    const handleOnline = () => {
      setOnline(true);
    };

    const handleOffline = () => {
      setOnline(false);
    };

    dispatch(changeScrolling(false));

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // Disabled Right Click
  useEffect(() => {
    const disableRightClick = (e) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", disableRightClick);
    document.onkeydown = (e) => {
      if (e.key == "F12") {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.key == "I") {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.key == "C") {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.key == "J") {
        e.preventDefault();
      }
      if (e.ctrlKey && e.key == "U") {
        e.preventDefault();
      }

      if (e.key === "Dead") {
        e.preventDefault();
      }
    };

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
    };
  }, []);

  const notifyMessage = useSelector((store) => store?.notifyMessage?.message);

  if (!headerData) {
    return;
  }
  //  it is used for the footer component to know
  const checkFooter = location.pathname.split("/").filter(Boolean).length;

  if (toggleScrolling) {
    document.querySelector("body").style.overflowY = "hidden";
  } else {
    document.querySelector("body").style.overflowY = "auto";
  }


  return (
    <>
      {notifyMessage && <NotifyAlert message={notifyMessage} />}

      {!showAllHeader && <AllHeader headerData={headerData} />}
      {/* <NotifyAlert /> */}

      {!checkFooter === 2 ? null : <ScrollToTop />}
      <ToastContainer />
      {online && !netErr ? null : <UserOffline />}
      <Routes>
        {routes.map((route, idx) => (
          <Route key={idx} path={route.path} element={route.element} />
        ))}
        {privateRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={<PrivateRoute Component={route.element} />}
          />
        ))}
      </Routes>
      {checkFooter >= 2 ? "" : !toggleScrolling && <Footer />}
      {/* {!toggleScrolling || (checkFooter !== 2) ? null : <Footer />} */}
    </>
  );
};

export default App;
