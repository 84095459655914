import React, { Suspense } from "react";
import ReactDOM, { hydrateRoot } from "react-dom/client";
import "./index.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/zoom";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import SearchState from "./components/context/SearchState";

// Redux
import store from "./Store/Store";
import { Provider } from "react-redux";
import ThemeSuspense from "./utils/ThemeSuspense/ThemeSuspence";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <SearchState>
        <Suspense fallback={<ThemeSuspense />}>
          <App />
        </Suspense>
      </SearchState>
    </Provider>
  </BrowserRouter>
);

// const containner = document.getElementById("root");
//  hydrateRoot(
//   containner,
//   <BrowserRouter>
//     <Provider store={store}>
//       <SearchState>
//         <Suspense fallback={<ThemeSuspense />}>
//           <App />
//         </Suspense>
//       </SearchState>
//     </Provider>
//   </BrowserRouter>
// );
