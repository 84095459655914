// useCurrency.js
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultCurrency } from '../Store/reducers/currencySlice';

const useCurrency = () => {
  const dispatch = useDispatch();
  const { defaultCurrency, data, symbol } = useSelector(store => store.currency);

  const handleCurrencyChange = (obj) => {
    dispatch(setDefaultCurrency(obj));
  };

  const handleChangeValue = (price) => {
    if (price) {
      return (price * data[defaultCurrency]).toFixed(2);
    } else {
      return 0
    }
  };

  return {
    handleCurrencyChange,
    handleChangeValue,
    defaultCurrency,
    symbol
  };
};

export default useCurrency;
