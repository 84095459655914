import React from 'react'
import notFound from '../../assets/404_img.png'
import { Link } from "react-router-dom";


const NotFound = () => {
    return (
        <div className='pt-12 w-full h-screen bg-[#FFF9F2] flex items-center justify-center flex-col'>
            <img src={notFound} alt="" className='w-[70%] h-[70%] object-contain' />
            <p>Page Not Found, Please Try Again or <Link to={"/"} className='text-blue-600 cursor-pointer underline'>Go Home</Link></p>
        </div>
    )
}

export default NotFound