import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NewsletterService from "../../services/NewsletterServices";

const UnSubscribeNewsletter = () => {
  const navigate = useNavigate();

  const { token } = useParams();

  useEffect(() => {
    const removeCustomerFromNewsletter = async () => {
      try {
        const res = await NewsletterService.unsubscibeCustomer(token);
        console.log(res);
      } catch (error) {
        console.log(error);
      }
    };

    removeCustomerFromNewsletter();
  }, [token]);

  if (!token) {
    // navigate("/");
    console.log("Return");
  }

  return (
    <div className="login-page">
      <div className="grid sm:grid-cols-2 grid-cols-1">
        <h6 className="login-title mb-[40px]">
        Newsletter Unsubscribe Successful !!
        </h6>
      </div>
      <p> Your Email has been Successfully Unsubscribe from the list !! </p>
    </div>
  );
};

export default UnSubscribeNewsletter;
