// currencySlice.js
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const Curr = JSON.parse(Cookies.get("currency") || "{}");

const currencySlice = createSlice({
  name: "currency",
  initialState: {
    data: {},
    defaultCurrency: Curr?.code || "INR",
    symbol: Curr?.symbol || "₹",
    showingCurrency: []
  },
  reducers: {
    setCurrencyData: (state, action) => {
      state.data = action.payload;
    },
    setDefaultCurrency: (state, action) => {
      Cookies.set(
        "currency",
        JSON.stringify({
          code: action.payload.code,
          symbol: action.payload.symbol,
        }),
        {
          sameSite: "None",
          secure: true,
        }
      );

      state.defaultCurrency = action.payload.code;
      state.symbol = action.payload.symbol;
    },

    setShowingCurrency: (state, action) => {
      state.showingCurrency = action.payload;
    }
  },
});

export const { setCurrencyData, setDefaultCurrency, setShowingCurrency } = currencySlice.actions;

export default currencySlice.reducer;
