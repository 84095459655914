import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({

    name: "cart",

    initialState: {
        carts: [],
    },

    reducers: {

        addCart: (state, action) => {
            state.carts = action.payload;
        },

        clearCarts: (state, action) => {
            state.carts = [];
        }
    }
});

export const { addCart, clearCarts } = cartSlice.actions;
export default cartSlice.reducer;