import { createSlice } from "@reduxjs/toolkit";

const clientSettingSlice = createSlice({

    name: "storeSettings",

    initialState: {
        toggleScrolling: false,
    },

    reducers: {

        changeScrolling: (state, action) => {
            state.toggleScrolling = action.payload;
        },

    }

});

export const { changeScrolling } = clientSettingSlice.actions;
export default clientSettingSlice.reducer;