import requests from './httpService';

const LanguageServices = {

  getShowingLanguage: async () => {
    return requests.get('/language/show');
  }
  
};

export default LanguageServices;