import { createSlice } from "@reduxjs/toolkit";

const categorySlice = createSlice({

    name: "categorySlice",

    initialState: {
        categoryId: null,
    },

    reducers: {

        addActiveCategoryID: (state, action) => {
            return action.payload;
        },
    }
});

export const { addActiveCategoryID } = categorySlice.actions;
export default categorySlice.reducer;