import requests from "./httpService";

const CustomerService = {
  registerCustomer: async (token) => {
    return requests.post(`/customer/register/${token}`);
  },

  verifyEmail: async (body) => {
    return requests.post("/customer/verify-email", body);
  },

  loginCustomer: async (body) => {
    return requests.post(`/customer/login`, body);
  },

  forgetPassword: async (body) => {
    return requests.put("/customer/forget-password", body);
  },

  resetPassword: async (body) => {
    return requests.put("/customer/reset-password", body);
  },

  getCustomerById: async (id) => {
    return requests.get(`/customer/${id}`);
  },

  checkCustomerVerified: async (id) => {
    return requests.get(`/customer/check/verified/${id}`);
  },

  updateCustomer: async (id, body) => {
    return requests.put(`/customer/${id}`, body);
  },

  addToWishlist: async (id, body) => {
    return requests.post(`/customer/add/wishlist/${id}`, body);
  },

  CheckWishlist: async (id, body) => {
    return requests.post(`/customer/check/wishlist/${id}`, body);
  },

  deleteALlWishlist: async (id) => {
    return requests.put(`/customer/remove-all/wishlist/${id}`);
  },

  removeFromWishlist: async (id, body) => {
    return requests.put(`/customer/remove/wishlist/${id}`, body);
  },

  getWishlist: async (id, body) => {
    return requests.get(`/customer/get/wishlist/${id}`);
  },

  getGroupMessages: async (id, body) => {
    return requests.get(`/customer/get/messages/${id}`);
  },

  getOrderCustomer : async () => {
    return requests.get(`/order/`,);
  },

  getCustomerOrder : async (id) => {
    return requests.get(`/orders/customer/${id}`,);
  }
};

export default CustomerService;
