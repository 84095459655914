import requests from "./httpService";

const CatgoryServices = {
  getShowingCategory: async () => {
    return requests.get("/category/show");
  },
  addTheCountsCategory: async (slug) => {
    return requests.get(`/category/add/counts/${slug}`);
  },
};
export default CatgoryServices;
