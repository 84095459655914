import { useEffect, useState } from 'react';
import LanguageServices from '../services/LanguageServices';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage, setShowingLanguages } from '../Store/reducers/languageSlice';

const useTranslation = () => {
  const dispatch = useDispatch();
  const { value: lang, name } = useSelector(store => store.language);

  const handleLanguageChange = (data) => {
    dispatch(setLanguage(data));
  };

  const showTranslatedValue = (data) => {
    if (!data) {
      return null;
    }

    return data[lang] || data.en || null;
  };

  return {
    showTranslatedValue,
    handleLanguageChange,
    name,
  };
};

export default useTranslation;