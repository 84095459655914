import React, { useCallback, useEffect, useMemo, useState } from "react";
import CustomerService from "../services/CustomerServices";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { notifySuccess } from "../utils/toast";
import ProductServices from "../services/productServices";
import { setNotifyMessage } from "../Store/reducers/notifySlice";
import { wislistProductID } from "../Store/reducers/productScrollSlice";

const useCustomer = () => {
  const dispatch = useDispatch();
  const [wishlist, setWishlist] = useState([]);
  const [messages, setMessages] = useState([]);
  const userInfo = useSelector((store) => store?.auth?.userInfo);
  const [user, setUser] = useState({});
  const [userAddress, setUserAddress] = useState({});

  const fetchWishlists = async () => {
    try {
      const resData = await CustomerService.getWishlist(userInfo._id);
      setWishlist(resData?.wishlist);
    } catch (err) {
      console.log("Error in Wishlist", err);
    }
  };

  const fetchGroupMessages = async () => {
    try {
      const resData = await CustomerService.getGroupMessages(userInfo._id);
      setMessages(resData?.groupId?.notifications);
    } catch (err) {
      console.log("Error in Group Messages", err);
    }
  };

  const handleAllDelete = async () => {
    const res = await CustomerService.deleteALlWishlist(userInfo._id);
    //  notifySuccess(res?.message);
    dispatch(setNotifyMessage(res?.message));
    setWishlist([]);
  };

  const handleUserAddress = async (e) => {
    try {
      e.preventDefault();

      const customerData = {
        name: e.target.name.value,
        companyName: e.target?.company?.value,
        vatNumber: e.target?.vatNumber?.value,
        phone: e.target?.phone?.value,
        address: e.target?.address?.value,
        address2: e.target?.address2?.value,
        pincode: e.target?.pincode?.value,
        country: e.target?.country?.value,
        state: e.target?.state?.value,
        city: e.target?.city?.value,
      };

      const res = await CustomerService.updateCustomer(
        userInfo._id,
        customerData
      );

      // notifySuccess('Customer Address Updated Successfully !!');
      dispatch(setNotifyMessage("Customer Address Updated Successfully !!"));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUser = async () => {
    try {
      const resData = await CustomerService.getCustomerById(userInfo._id);
      dispatch(wislistProductID(resData?.wishlist));
      setUser(resData);
      if (resData?.userAddress?.length) {
        setUserAddress(resData?.userAddress[0]);
      }
    } catch (err) {
      console.log("Error in FetchUser Messages", err);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleDelete = async (id) => {
    try {
      const res = await CustomerService.removeFromWishlist(userInfo._id, {
        productId: id,
      });
      // notifySuccess(res?.message);
      dispatch(setNotifyMessage(res?.message));
      fetchWishlists();
    } catch (error) {
      console.log(error);
    }
  };

  return {
    wishlist,
    fetchWishlists,
    messages,
    fetchGroupMessages,
    handleUserAddress,
    user,
    fetchUser,
    handleDelete,
    userAddress,
    handleAllDelete,
  };
};

export default useCustomer;
