import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setEmptyMessage } from "../Store/reducers/notifySlice";
// import { MdOutlineCancel } from "react-icons/md";
import { TfiClose } from "react-icons/tfi";


const notifySuccess = (message) =>
  toast.success(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const notifyError = (message = "Internal Server Error") =>
  toast.error(message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

const NotifyAlert = ({ message }) => {
  const [toggleMsg, setToggleMsg] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let count = 0;

    const intervalId = setInterval(() => {
      setToggleMsg(true);
      count += 1;
      if (count > 3) {
        clearInterval(intervalId);
        setToggleMsg(false);
        dispatch(setEmptyMessage(""));
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once on component mount


  return (
    <div className={`w-full bg-[#A03907] py-2 flex items-center relative justify-center text-white ${!toggleMsg && 'hidden'} sticky top-0 z-[999]`}
    >
      <span className={`w-[90%] flex items-center justify-center transition-all duration-300 ease-in-out`}>
        {message} </span>
      <span className="cursor-pointer absolute top-0 right-[20px] flex items-center justify-center h-10"
        onClick={() => { setToggleMsg(false); dispatch(setEmptyMessage("")) }}
      >
        <TfiClose className="w-4 h-4" />

      </span>
    </div>
  );
}

export default NotifyAlert;


export { notifySuccess, notifyError };