import React, { useContext, useEffect, useState } from "react";
import "./header.css";
import $ from "jquery";
import SearchContext from "../context/SearchContext";
import { Link } from "react-router-dom";
import useTranslation from "../../hooks/useTranslation";
import useCurrency from "../../hooks/useCurrency";
import { useSelector } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import { FaRegHeart } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { HiOutlineShoppingBag } from "react-icons/hi2";
import useProductDetails from "../../hooks/useProductDetails";
import { IoMdHeartEmpty } from "react-icons/io";
import { FiSearch } from "react-icons/fi";
import { BiUser } from "react-icons/bi";
import { motion } from "framer-motion";
import styled from "styled-components";
import useCart from "../../hooks/useCart";


function AllSubHeader() {
  const searchState = useContext(SearchContext);
  const { HandleCart, HandleSearch } = searchState;
  const [showSubHeader, setShowSubHeader] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const headerData = useSelector((store) => store?.storeSettings?.header);
  const {
    handleLanguageChange,
    showTranslatedValue,
    name: languageName,
  } = useTranslation();
  const { handleCurrencyChange, defaultCurrency } = useCurrency();

  const { showingCurrency: currencies } = useSelector(store => store.currency);

  const { showingLanguages: languages } = useSelector(store => store.language);

  const { cartLength } = useCart();

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    const isScrollingDown = currentScrollPos > prevScrollPos;
    setShowSubHeader(!isScrollingDown || currentScrollPos < 200);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(() => {
    $(window).on("scroll", function () {
      if ($(window).scrollTop() >= 0) {
        $(".sub-header-container").css({ "background-color": "#fff9f2" });
      } else {
        $(".sub-header-container").css({ "background-color": "#fff9f2" });
      }
      if ($(this).scrollTop() > 100) {
        $(".sub-header-text, .sub-header-text b, .search-icon, .my-account-icon, .wishlist-icon, .header-cart-btn, .header-cart-btn, .reel-text-container, .sub-header-text, .cart-btn-, .language-text, .currency-text, .reel-text, .header-cart-btn-container h2, .cart-bag-and-circle svg").css(
          "color",
          "#000"
        );
      } else {
        $(".sub-header-text, .sub-header-text b, .search-icon, .my-account-icon, .wishlist-icon, .header-cart-btn, .header-cart-btn, .reel-text-container, .sub-header-text, .cart-btn-,.language-text, .currency-text, .reel-text, .header-cart-btn-container h2, .cart-bag-and-circle svg").css(
          "color",
          "#000"
        );
      }
    });
  }, []);


  if (!headerData) {
    return;
  }

  // const marqueeVariants = {
  //   animate: {
  //     x: [0, -100],
  //     transition: {
  //       x: {
  //         repeat: Infinity,
  //         repeatType: "loop",
  //         duration: 5,
  //         ease: "linear",
  //       },
  //     },
  //   },
  // };

  const InfiniteMarquee = ({ headerData }) => {
    return (
      // <div className="overflow-hidden whitespace-nowrap flex justify-end items-center w-[100%] ">
      //   {/* <motion.div
      //     className="reel-text-container inline-block"
      //     variants={marqueeVariants}
      //     animate="animate"
      //     style={{
      //       fontFamily: "'Theinhardt Pan', sans-serif",
      //       fontSize: "15px",
      //       fontWeight: 300,
      //     }}
      //   >
      //     {headerData}
      //   </motion.div> */}
      //   <div className="marquee-ele !text-black">
      //     <span>{headerData}</span>
      //     <span>{headerData}</span>
      //     <span>{headerData}</span>
      //   </div>
      //   {/* <div className="marquee-ele2">{headerData}</div> */}
      // </div>
      <div className="reel-box-container mx-auto flex justify-center items-center">
        <div className="overflow-hidden whitespace-nowrap">
          <motion.div
            className="reel-text"
            style={{ display: 'inline-block', whiteSpace: 'nowrap' }}
            animate={{ x: '-50%' }}
            transition={{ repeat: Infinity, duration: 20, ease: 'linear' }}
          >
            {headerData}{' '}
            {headerData}{' '}
          </motion.div>
        </div>
      </div>
    );
  };
  return (
    <Wrapper>
      <div className={`sub-header-container ${showSubHeader ? "visible" : "hidden"} !bg-[#fff9f2]`}>

        {/* grid-col-1 */}
        <InfiniteMarquee
          headerData={showTranslatedValue(headerData?.header_text)}
        />

        {/* grid-col-2 */}
        <div className="sub-item-container-grid">
          {/* ----> grid-col-2.1 ( Language & Currency ) <----- */}
          <div className="language-and-currency-contianer">
            <div className="language">
              <p className="language-text-" style={{ color: "#000" }}>{languageName}</p>
              <ul className="language-list">
                {languages?.map((eachLang) => <li key={eachLang._id} onClick={() => handleLanguageChange({ lang: eachLang?.iso_code, name: eachLang?.name?.toUpperCase() })}>{eachLang?.name?.toUpperCase()}</li>)}
              </ul>
            </div>
            <div className="currency">
              <p className="currency-text-" style={{ color: "#000" }}>{defaultCurrency}</p>
              <ul className="currency-list">
                {currencies?.map((eachCurr) => <li key={eachCurr._id} onClick={() => handleCurrencyChange({ code: eachCurr?.code, symbol: eachCurr.symbol })}>{`${eachCurr?.name?.toUpperCase()} (${eachCurr?.symbol?.toUpperCase()})`}</li>)}
              </ul>
            </div>
          </div>

          {/* ----> grid-col-2.2 ( Seach-account-wishlist-icons ) <----- */}
          <div className="Seach-account-wishlist-icons" id="subheader-icon">
            <button className="icon-button" onClick={HandleSearch} >
              <FiSearch className="search-icon icon" />
            </button>
            <Link to="/my-account" className="icon-link">
              <BiUser className="my-account-icon icon" />
            </Link>
            <Link to="/wishlist" className="icon-link">
              <IoMdHeartEmpty className="wishlist-icon icon" />
            </Link>
          </div>

          {/* ----> grid-col-2.3 ( Cart-text-and-icons ) <----- */}
          <div className="header-cart-btn-container" onClick={HandleCart}>
            <h2>Cart</h2>
            <div className="cart-bag-and-circle">
              <HiOutlineShoppingBag />
              <div className="cart-qty-circle-home">
                {cartLength ? <span>{cartLength}</span> : <span>0</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
export default AllSubHeader;




const Wrapper = styled.div`

  .sub-header-container{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 555;
    color: white;
    border-bottom: 1px solid #D9D9D9;
    transition: all 0.5s;
    
    display: grid;
    grid-template-columns: 70% 30%;
    /* background-color: #077955; */
    align-items: center;
  }

  .reel-box-container{
    width: 100%;
    grid-column: 1/2;
  }

  .sub-item-container-grid{
    width: 100%;
    grid-column: 2/-1;

    display: grid;
    grid-template-columns: 1fr 1.1fr 1fr;
    align-items: center;
    color: #000 !important;
  }

  .language-and-currency-contianer{
    display: flex;
    position: relative;

    grid-column: 1/2;
    border-left: 1px solid #d9d9d9;
    border-right: 1px solid #d9d9d9;
    color: #000 !important;

  }

  .Seach-account-wishlist-icons{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    place-items: center;
    color: #000 !important;

    grid-column: 2/3;
    border-right: 1px solid #d9d9d9;
    padding: 0rem 1.5rem;
    gap: 1rem;
  }


  .header-cart-btn-container{
    width: 100%;
    height: 100%;
    grid-column: 3/-1;
  
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    color: #000 !important;
  }


  .header-cart-btn-container h2 {
    font-family: Theinhardt Pan, sans-serif;
    font-size: 1.1rem;
    font-weight: 300;
    font-style: normal;
    line-height: normal;
    color: #000 !important;
    padding-top: 7px;

  }

  .cart-bag-and-circle{
    position: relative;
  }

  .cart-bag-and-circle .cart-qty-circle-home{
    position: absolute;
    top: -0.8rem;
    right: -1rem;
    z-index: 10;
  }

  .cart-bag-and-circle svg {
    font-size: 1.3rem !important;
    color: #000 !important;
  }

  .reel-text{
    font-family: Theinhardt Pan, sans-serif;
    font-size: 15px;
    font-weight: 300;
    color: #000 !important;
  }

  #subheader-icon .icon{
    color: #000 !important;
  }

  @media (max-width: 1182px) {
    .sub-header-container{
      grid-template-columns: 60% 40% !important;
    }
  }  

`