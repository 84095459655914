import Cookies from 'js-cookie';
import React from 'react'

import { Navigate } from 'react-router-dom';
import { notifyError } from '../utils/toast';
import { useDispatch } from 'react-redux';
import { setNotifyMessage } from '../Store/reducers/notifySlice';

const PrivateRoute = ({Component}) => {

    const userInfo = JSON.parse(Cookies.get("userInfo") || null);
   const dispatch=useDispatch();
    if(!userInfo){
      // notifyError("Kindly log in to continue.");
      dispatch(setNotifyMessage("Kindly log in to continue."));
    }
  // const userInfo = true;
  
  return (
    !userInfo ? <Navigate to="/login" /> : Component
  )
}

export default PrivateRoute;